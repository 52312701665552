// ShimmerEffect.js
import React from "react";
import "../../styles/PG4/shimmer-effect.scss";
import { useTranslation } from "react-i18next";

const L_RecommendationItem = () => {
  const { t } = useTranslation();
  return (
    <div
      className="shimmer-effect__wrapper"
      role="status"
      aria-live="polite"
      aria-label={t("sr_txt_7")}
    >
      <div className="shimmer-effect__item" aria-hidden="true"></div>
      <div className="shimmer-effect__item" aria-hidden="true"></div>
      <div className="shimmer-effect__item" aria-hidden="true"></div>
      <div className="shimmer-effect__item" aria-hidden="true"></div>
      <div className="shimmer-effect__item" aria-hidden="true"></div>
    </div>
  );
};

export default L_RecommendationItem;
