import React, { Component, useEffect, useState } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import TextInput from "./TextInput";
import PasswordInput from "./PasswordInput ";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { validateEmail } from "../methods";
import { pureAccountCall } from "../store/actions/rialtoManagement";
import toast from "react-hot-toast";
import { registerPureAccountCall } from "../store/actions/auth";
import ButtonWithLoader from "../components/ButtonWithLoader";

function PureAccountModelVd({
  showModal,
  onCancel,
  token,
  currentPracticeID,
  currentPractice,
  isLogin,
  onRefresh,
  enableClose,
}) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [password, setPassword] = useState("");
  const [pureLoading, setPureLoading] = useState(false);
  const [withoutPureLoading, setWithoutPureLoading] = useState(false);

  // redux actions
  const dispatch = useDispatch();
  const pureAccountCallAction = (
    data,
    token,
    currentPracticeID,
    currentPractice
  ) =>
    dispatch(pureAccountCall(data, token, currentPracticeID, currentPractice));
  const registerPureAccountCallAction = (data) =>
    dispatch(registerPureAccountCall(data));

  const submitPureAccountCall = async () => {
    const data = {
      email: email,
      password: password,
      website: 1,
    };
    setPureLoading(true);
    const response = await pureAccountCallAction(
      data,
      token,
      currentPracticeID,
      currentPractice
    );
    setPureLoading(false);
    if (response?.payload?.success === true) {
      onCancel();
      setEmail("");
      setPassword("");
      onRefresh(response.payload);
      toast.success(response.payload.message);
    } else {
      toast.error(response.payload.message);
    }
  };

  const submitRegisterPureAccountCall = async () => {
    const data = {
      email: email,
      password: password,
      website: 1,
    };
    setPureLoading(true);
    const response = await registerPureAccountCallAction(data);
    setPureLoading(false);
    if (response?.payload?.success === true) {
      console.log(response);
      onCancel();
      onRefresh(response.payload);
      setEmail("");
      setPassword("");
      toast.success(response.payload.message);
    } else {
      toast.error(response.payload.message);
    }
  };

  const submitWithoutPureAccount = () => {
    setWithoutPureLoading(true);
    onCancel();
    setEmailError(false);
    setWithoutPureLoading(false);
  };

  const submitPanForm = () => {
    if (
      emailError ||
      // passwordError ||
      email.length === 0
      // || password.length === 0
    ) {
      setEmailError(true);
      // setPasswordError(true);
    } else if (passwordError || password.length === 0) {
      // setEmailError(true);
      setPasswordError(true);
    } else {
      isLogin ? submitPureAccountCall() : submitRegisterPureAccountCall();
    }
  };

  let onTextInput = (e) => {
    if (!validateEmail(e.target.value.trim())) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setEmail(e.target.value.toLowerCase());
    }
  };

  let onPasswordInput = (e) => {
    // if (!validatePassword(e.target.value)) {
    //   setPasswordError(true);
    // } else {
    //   setPasswordError(false);
    setPassword(e.target.value);
    // }
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      contentClassName="border-radius"
      backdrop="static"
      className=""
      scrollable={true}
      keyboard={false}
    >
      <Modal.Header>
        <div className="pg-text text-center">
          <div className="pgs-heading my-3 mx-4 text-center textc-primary">
            {isLogin ? t("verfiy_pa") : t("register_with_your_pa")}{" "}
          </div>
          <i className="text-center fs-6 pgs-text">
            {isLogin ? (
              <p>
                {t("pure_account_model_para_1")}{" "}
                <a href="https://www.pureencapsulationspro.com/">
                  {t("education_drop_2_txt3")}
                </a>{" "}
                {t("pure_account_model_reg_txt")}
              </p>
            ) : (
              <p>{t("pure_account_model_para_2")}</p>
            )}
          </i>
        </div>
        <div
          style={{
            position: "absolute",
            right: 25,
            top: 15,
            fontSize: 20,
            cursor: "pointer",
            color: "var(--pg_primary)",
          }}
          onClick={() => {
            onCancel();
            setEmailError(false);
            setPasswordError(false);
            setPasswordError(false);
          }}
        >
          {enableClose && <div className="fas fa-times"></div>}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <TextInput
            // label="Email Address"
            type="text"
            showLabel={false}
            inputHandler={onTextInput}
            placeholder={t("auth_page_email_ph")}
            error={emailError}
            errorMessage={t("auth_page_email_error")}
            id="email-input__loginpage"
            emailField={true}
          />
          <PasswordInput
            // label="Password"
            inputHandler={onPasswordInput}
            placeholder={t("auth_page_pwd_ph")}
            error={passwordError}
            errorMessage={t("auth_page_pwd_error")}
            id="password-input__loginpage"
          />
          <div className="modal-footer">
            {" "}
            <ButtonWithLoader
              className="btn w-100 btn-signin"
              onClick={submitPanForm}
              disabled={pureLoading}
              loading={pureLoading}
              spinnerClass="text-primary"
            >
              {isLogin ? t("submit") : t("register_with_pa")}
            </ButtonWithLoader>
            {!isLogin && (
              <ButtonWithLoader
                className="btn w-100 btn-signin"
                onClick={submitWithoutPureAccount}
                disabled={withoutPureLoading}
                loading={withoutPureLoading}
                spinnerClass="text-primary"
              >
                {t("pure_account_model_btn_1")}
              </ButtonWithLoader>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default PureAccountModelVd;
