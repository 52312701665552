import React, { useMemo, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import TermsAndConditions from "../TermsAndConditions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { fr } from "date-fns/locale";
import { format } from "date-fns";
import _ from "lodash";
import { isValidDate } from "../../methods";

export default function PracticeTermsTable({ termsData, error }) {
  const { t, i18n } = useTranslation();
  const [showTerms, setShowTerms] = useState(false);
  const [terms, setTerms] = useState("");
  const [title, setTitle] = useState("");

  const handleShow = (props, type) => {
    setShowTerms(true);
    if (type === "Practice Agreement") {
      setTerms(
        _.filter(props.data, { title: "Practice Agreement" })[0].content
      );
      setTitle("PUREGENOMICS PLATFORM TERMS OF USE");
    } else if (type === "Ca Practice Agreement") {
      setTerms(
        _.filter(props.data, { title: "Ca Practice Agreement" })[0].content
      );
      setTitle("PUREGENOMICS PLATFORM TERMS OF USE");
    } else if (type === "Uk Practice Agreement") {
      setTerms(
        _.filter(props.data, { title: "Uk Practice Agreement" })[0].content
      );
      setTitle("PUREGENOMICS PLATFORM TERMS OF USE");
    } else if (type === "Virtual Dispensary Agreement") {
      setTerms(
        _.filter(props.data, { title: "Virtual Dispensary Agreement" })[0]
          .content
      );
      setTitle("VIRTUAL DISPENSARY TERMS OF USE");
    } else if (type === "Pg4 Practice Agreement") {
      setTerms(
        _.filter(props.data, { title: "Pg4 Practice Agreement" })[0].content
      );
      setTitle("PUREGENOMICS PLATFORM TERMS OF USE");
    } else if (type === "Rebranding Agreement") {
      setTerms(
        _.filter(props.data, { title: "Rebranding Agreement" })[0].content
      );
      setTitle("NOTICE AND ACKNOWLEDGEMENT");
    }
  };

  const handleHide = () => {
    setShowTerms(false);
  };

  const column = [
    {
      Header: t("pract_account_th1"),
      accessor: "title",
      Cell: (props) => (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => handleShow(props, props.cell.value)}
        >
          {props.cell.value === "Pg4 Practice Agreement"
            ? t("prac_agreement")
            : props.cell.value}
        </a>
      ),
    },
    {
      Header: t("pract_account_th2"),
      accessor: "agreed_by",
    },
    {
      Header: t("pract_account_th3"),
      accessor: "agreed_at",
      Cell: (props) => (
        <span>
          {isValidDate(props.cell.value)
            ? format(new Date(props.cell.value), "MMM do, yyyy", {
                locale: i18n.language === "fr" ? fr : undefined,
              })
            : props.cell.value}
          {/* {moment(props.cell.value).format("MMM Do YYYY, h:mm:ss a")} */}
        </span>
      ),
    },
  ];

  const columns = useMemo(() => column, []);
  const data = useMemo(() => termsData, [termsData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <>
      <table {...getTableProps()} className="pg-table genotype-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        {error && (
          <tbody>
            <tr>
              <td colSpan="3">
                <div className="w-100 text-center">
                  {t("pract_account_tmsg1")}
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {termsData.length < 1 && !error && (
          <tbody>
            <tr>
              <td colSpan="3">
                <div className="w-100 text-center">
                  {t("pract_account_tmsg2")}
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {termsData.length > 0 && !error && (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      <TermsAndConditions
        showModal={showTerms}
        content={terms}
        title={title}
        showClose={true}
        showButton={false}
        handleHide={() => handleHide()}
      />
    </>
  );
}
