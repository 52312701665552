const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const approvePatients = async ({
  selectedPatients,
  currentPractice,
  token,
  currentPracticeID,
}) => {
  const response = await fetch(
    `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/approve`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Current-Practice": currentPracticeID,
      },
      body: JSON.stringify({ ids: selectedPatients }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to approve patients");
  }

  return response.json();
};

export const rejectPatients = async ({
  selectedPatients,
  currentPractice,
  token,
  currentPracticeID,
}) => {
  const response = await fetch(
    `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/reject`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Current-Practice": currentPracticeID,
      },
      body: JSON.stringify({ ids: selectedPatients }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to reject patients");
  }

  return response.json();
};

export const fetchSelfRegisteredPatients = async ({
  token,
  currentPracticeID,
  currentPractice,
  pageSize,
  sPageIndex,
  searchText,
}) => {
  // Construct the query parameters for pagination and search
  const url = new URL(
    `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/awaiting_approval_list`
  );

  url.searchParams.append("per_page", pageSize);
  url.searchParams.append("page", sPageIndex + 1); // Page is 1-based index

  if (searchText) {
    url.searchParams.append("full_name", searchText);
  }

  // Make the request
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
  });

  if (!response.ok) {
    throw new Error("Error fetching patient list");
  }

  return response.json(); // Return the parsed JSON data
};
