import { useDispatch, useSelector } from "react-redux";
import { getMSQResponses } from "../../../store/actions/pg4report";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import L_FocusCardDetail from "../../LoadingEffectComponent/PG4/L_FocusCardDetail";
import LabReviewModal from "../../Practice/Lab/LabReviewModal";
import Popup from "reactjs-popup";
import { ReactComponent as InfoIcon } from "../../../assets/info.svg";
import PgInfoTooltip from "../../PgInfoTooltip";
import { stringifiedArray } from "../../../methods";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { questionnaireStarted } from "../../../store/actions/pg4dashboard";
import { useTranslation } from "react-i18next";

export default function PatientDetailsCard({ data }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMSQResponses, setshowMSQResponses] = useState(false);
  const [showLabReviewModal, setShowLabReviewModal] = useState(false);
  const userType = useSelector((state) => state.auth.userType);
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const questionnaireStartedAction = (session_id) =>
    dispatch(
      questionnaireStarted({
        token,
        patient_id: data.patient_id,
        currentPractice,
        session_id,
      })
    );

  const onStartQuestionnaire = async () => {
    const response = await questionnaireStartedAction(data?.msq?.session_id);
    console.log({ response });
    navigate("/patient/msq", {
      state: {
        card_type: "questionnaire_msq",
        questionnaire_id: data?.msq?.questionnaire_id,
        session_id: data?.msq?.session_id,
        heading: t("msq"),
      },
    });
  };

  return (
    <div className="bg_secondary p-2 p-lg-3 my-4 rounded">
      {showLabReviewModal && (
        <LabReviewModal
          modalData={{
            documentIds: data?.lab_document_ids,
            documentId: data?.lab_session_id,
            patientId: data?.patient_id,
            completed: true,
            from: "report",
          }}
          showModal={showLabReviewModal}
          onCancel={() => setShowLabReviewModal(false)}
        />
      )}
      {showMSQResponses && (
        <MSQResponsesModal
          report_id={data.report_id}
          show={showMSQResponses}
          onClose={() => setshowMSQResponses(false)}
        />
      )}
      <div className="row justify-content-between">
        <div className="col-12 col-lg-8">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <div className="pg4-text__heading text_primary">
                {data?.patient_name}
              </div>

              {data?.age >= 0 && (
                <div className="my-2">
                  {t("pracand_age")}: {data?.age}
                </div>
              )}
              {data?.gender && (
                <div className="my-2">
                  {t("pg4_report_txt_6")} : {data?.gender}
                </div>
              )}
              {!_.isEmpty(data?.allergies) && (
                <div className="my-2">
                  {t("allergies")} : {stringifiedArray(data?.allergies)}
                </div>
              )}
            </div>
            <div className="col-12 col-lg-6">
              <div className=" mb-3">
                <div className="pg4-text__subheading text_primary">
                  {t("report_focus_card_txt_2")}
                </div>
                {data?.questionnaire_completed ? (
                  <div
                    className="pg-link"
                    onClick={() => setshowMSQResponses(true)}
                  >
                    {t("pracpl_btn_9")} {">"}
                  </div>
                ) : (
                  t("pracpl_line_12") // N/A
                )}
              </div>
              <div className=" my-3">
                <div className="pg4-text__subheading text_primary">
                  {t("pracrs_line_1")}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.genetic?.name
                      ? data?.genetic?.name
                      : t("pracpl_line_12"), // "N/A"
                  }}
                />
              </div>
              <div className=" my-3">
                <div className="pg4-text__subheading text_primary">
                  {t("lab_results")}
                </div>
                {data?.lab_session_id?.length == 0 ? (
                  t("pracpl_line_12")
                ) : (
                  <div
                    className="pg-link"
                    onClick={() => setShowLabReviewModal(true)}
                  >
                    {t("view_lab_data")} {">"}
                  </div>
                )}
              </div>
            </div>
            <div className="col-11 p-2 p-lg-3 rounded bg_tertiary mt-lg-5 my-2">
              <div className="pg-text__subheading text_primary">
                {t("pg4_report_txt_7")}
              </div>
              <div>
                <b>{stringifiedArray(data?.current_health_concerns)}</b>
              </div>
            </div>
          </div>
        </div>
        {
          <div className="col-12 col-lg-4">
            {!data?.msq?.show_msq_banner ? (
              <>
                {data?.questioniare_scores?.MSQ?.score >= 0 && (
                  <div>
                    <div className="d-flex justify-content-between">
                      <div className="pg4-text__subheading text_primary">
                        {t("msq_score")}
                      </div>
                      {userType === "User" && (
                        <PgInfoTooltip style={{ padding: 12, width: "auto" }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t("msq_tooltip_1"),
                            }}
                          />
                        </PgInfoTooltip>
                      )}
                    </div>
                    <div
                      className="pg4-text__title my-2"
                      style={{ fontSize: 50 }}
                    >
                      <MSQScoreAndArrow
                        score={data?.questioniare_scores?.MSQ?.score}
                        previous_score={
                          data?.questioniare_scores?.MSQ?.previous_score
                        }
                      />
                    </div>
                    <div className="pg4-text__subheading my-2">
                      {t("prev_msq_score")}:{" "}
                      <b>{data?.questioniare_scores?.MSQ?.previous_score}</b>
                    </div>
                    <div className="pg4-text my-2">{t("pg4_report_txt_8")}</div>
                    <hr />
                  </div>
                )}
              </>
            ) : (
              userType !== "User" && (
                <>
                  <div className="pg4-text__subheading text_primary">
                    {t("msq_score")}
                  </div>
                  <button
                    className="pg4-btn pg4-btn_primary mt-4 my-2 w-sm-100"
                    onClick={onStartQuestionnaire}
                  >
                    {t("patient_dashboard_p4")}
                  </button>
                  <hr />
                </>
              )
            )}
            {Object.entries(data?.questioniare_scores || {}).map(
              ([avq, scores]) => {
                if (avq !== "MSQ") {
                  const { score, previous_score } = scores || {};
                  return (
                    <div key={avq}>
                      <div className="pg4-text__subheading">
                        {avq} score: <b>{JSON.stringify(score)}</b>
                        {userType === "User" && avq?.includes("GAD") && (
                          <PgInfoTooltip
                            style={{ padding: "12px", width: "auto" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: t("msq_tooltip_2"),
                              }}
                            />
                          </PgInfoTooltip>
                        )}
                        {userType === "User" && avq?.includes("PSS") && (
                          <PgInfoTooltip
                            style={{ padding: "12px", width: "auto" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: t("msq_tooltip_3"),
                              }}
                            />
                          </PgInfoTooltip>
                        )}
                        {userType === "User" && avq?.includes("PHQ") && (
                          <PgInfoTooltip
                            style={{ padding: "12px", width: "auto" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: t("msq_tooltip_4"),
                              }}
                            />
                          </PgInfoTooltip>
                        )}
                      </div>
                      <div className="pg4-text">
                        {t("previous")} {avq} {t("score")} :{" "}
                        {JSON.stringify(previous_score)}
                      </div>
                    </div>
                  );
                }
                return null;
              }
            )}
          </div>
        }
      </div>
    </div>
  );
}

const MSQScoreAndArrow = ({ score, previous_score }) => {
  const _score = parseInt(score);
  const _previous_score = parseInt(previous_score);
  // const _previous_score = 122;
  // const _score = 14;
  return (
    <span className="position-relative">
      {/* {previous_score} */}
      <span
        style={{
          color:
            _score > 0 && _score <= 12
              ? "green"
              : _score > 12 && _score <= 37
              ? "orange"
              : "red",
        }}
      >
        {score}
      </span>
      {!Number.isNaN(_previous_score) && (
        <i
          className="fas fa-arrow-right position-absolute"
          style={{
            fontSize: 20,
            transform: `rotate(${
              _score > _previous_score ? "-45deg" : "45deg"
            })`,
            right: 0,
            top: 10,
          }}
        ></i>
      )}
      <span className="fw-bold" style={{ fontSize: "12px" }}>
        /108
      </span>
    </span>
  );
};

const MSQResponsesModal = ({ data, show, onClose, report_id }) => {
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.token);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const [isLoading, setIsLoading] = useState(true);
  const [responses, setResponses] = useState({});
  const userType = useSelector((state) => state.auth.userType);
  const dispatch = useDispatch();
  const getMSQResponsesAction = () =>
    dispatch(
      getMSQResponses({ currentPracticeID, currentPractice, token, report_id })
    );

  useEffect(() => {
    getMSQResponsesAction().then((response) => {
      setIsLoading(false);
      if (!response.error) {
        setResponses(response?.payload?.data);
      }
    });
  }, []);
  return (
    <Modal
      fullscreen="md-down"
      scrollable
      backdrop="static"
      show={show}
      onHide={onClose}
      size="lg"
    >
      <Modal.Header>
        <div
          onClick={onClose}
          className="cp position-absolute fw-bold px-2 top-0 end-0 mt-3 me-3"
        >
          <i className="cp fas fa-times fs-3 text_primary" />
        </div>
        <div className="pg4-text__title text_primary">
          <div className="pg4-text__title text-center">{t("msq_reponses")}</div>
        </div>
      </Modal.Header>
      <Modal.Body className="relative">
        {isLoading ? (
          <L_FocusCardDetail />
        ) : (
          <>
            <div className="">
              <div className="">
                <div className="my-3">
                  {Object.entries(responses || {})?.map(
                    ([q_type, response], idxT) => (
                      <div className="my-3" key={idxT}>
                        <div className="pg4-text__heading text_primary">
                          {q_type}
                        </div>
                        <div>
                          {Array.isArray(response)
                            ? response?.map((qAnda, idx) => (
                                <React.Fragment key={idx}>
                                  <div className=" py-2 row justify-content-between flex-flow my-2">
                                    <div className="fw-bold col-12 col-md-8">
                                      {t(qAnda.question)}
                                    </div>
                                    <div className="col-12 col-md-3 mt-2 my-md-auto">
                                      {qAnda.answer?.join(",")}
                                    </div>
                                  </div>
                                  <hr />
                                </React.Fragment>
                              ))
                            : null}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
