import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";

export const usePendingPatientsTable = ({
  data,
  controlledPageCount,
  sPageIndex,
}) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: ({ getToggleAllPageRowsSelectedProps }) => (
          <div className="custom-checkbox">
            <input
              type="checkbox"
              {...getToggleAllPageRowsSelectedProps()}
              aria-label="Select All Patients"
            />
            <span className="checkmark"></span>
          </div>
        ),
        id: "selection",
        Cell: ({ row }) => {
          return (
            <div className="custom-checkbox">
              <input
                type="checkbox"
                {...row.getToggleRowSelectedProps()}
                aria-label={`Select patient ${row.original.first_name} ${row.original.last_name}`}
              />
              <span className="checkmark"></span>
            </div>
          );
        },
        disableSortBy: true,
        width: 80,
      },
      {
        Header: t("pracand_fname"),
        accessor: "first_name",
      },
      {
        Header: t("pracand_lname"),
        accessor: "last_name",
      },
      {
        Header: t("email"),
        accessor: "email",
      },
    ],
    [t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { selectedRowIds, pageIndex },
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetSelectedRows: false,
      initialState: { pageIndex: sPageIndex },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );
  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    prepareRow,
    selectedFlatRows,
    selectedRowIds,
    pageIndex,
    toggleAllRowsSelected,
  };
};
