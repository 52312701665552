import React, { useEffect, useState } from "react";
import "../../../../styles/PG4/pgradio.scss";
import "../../../../styles/PG4/pg4-report.scss";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getRecommendations } from "./recommendation_api";
import L_RecommendationItem from "../../../LoadingEffectComponent/L_RecommendationItem";
import { AnimatePresence, motion } from "framer-motion";

function ExistingRecommendations({
  onLoad,
  onBack,
  deleteMutation,
  context,
  t,
}) {
  const [selectedId, setSelectedId] = useState(null);
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);

  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );

  const { data, isLoading } = useQuery(
    ["recommendations", currentPracticeID, context],
    () =>
      getRecommendations({
        currentPractice,
        currentPracticeID,
        token,
        recommendation_category: context,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const handleDelete = () => {
    if (selectedId) {
      deleteMutation.mutate(selectedId, {
        onSuccess: () => {
          setSelectedId(null);
          setSelectedRecommendation(null);
        },
        onError: (error) => {
          console.error("Deletion Error:", error);
        },
      });
    }
  };

  const listItemVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    }),
    exit: {
      opacity: 0,
      x: -100,
      transition: { duration: 0.2 },
    },
  };

  return (
    <div>
      <div className="d-flex">
        <h2
          className="pg4-text__heading text_primary"
          id="recommendations-heading"
        >
          {t("sr_txt_6")}
        </h2>
      </div>
      <hr className="my-0 mb-2" />

      {isLoading ? (
        <div
          style={{ height: "300px", width: "100%", overflow: "hidden" }}
          role="status"
          aria-live="polite"
          aria-label="Loading recommendations, please wait."
        >
          <L_RecommendationItem />
        </div>
      ) : (
        <fieldset
          className="rec-list px-2"
          style={{ height: "300px", overflowY: "scroll" }}
        >
          <legend className="visually-hidden">Recommendation List</legend>
          <AnimatePresence>
            {Array.isArray(data?.recommendations) &&
            data?.recommendations?.length > 0 ? (
              data?.recommendations?.map((rec, index) => (
                <motion.li
                  key={rec.id}
                  className="d-flex align-items-center rec-list_item"
                  custom={index}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={listItemVariants}
                  layout
                  role="radio" // Define each item as a radio button in ARIA
                  aria-checked={selectedId === rec.id}
                >
                  <input
                    id={"rec-radio-" + rec.id}
                    type="radio"
                    name="selectedRecommendation"
                    className="pg-radio me-2"
                    checked={selectedId === rec.id}
                    onChange={() => {
                      setSelectedId(rec.id);
                      setSelectedRecommendation(rec);
                    }}
                    aria-labelledby={`rec-label-${rec.id}`} // Associate input with label
                  />
                  <label
                    id={`rec-label-${rec.id}`}
                    htmlFor={"rec-radio-" + rec.id}
                    className={`d-flex rec-list_text--container ${
                      selectedId === rec.id ? "selected" : ""
                    }`}
                  >
                    <div>
                      <strong className="rec-list_text--title">
                        {rec.title}
                      </strong>
                      <p className="mb-0 rec-list_text--body">
                        {rec.recommendation_text}
                      </p>
                    </div>
                  </label>
                </motion.li>
              ))
            ) : (
              <p
                className="text-center text-danger fw-bolder my-3"
                aria-live="polite"
              >
                {t("sr_txt_8")} {/* No Recommendations */}
              </p>
            )}
          </AnimatePresence>
        </fieldset>
      )}

      <div className="mt-3 d-flex flex-column ">
        <button
          className="pg4-btn pg4-btn_primary w-100"
          disabled={!selectedId}
          onClick={() => onLoad(selectedRecommendation)}
          aria-disabled={!selectedId} // Ensure screen readers understand the disabled state
          aria-describedby="recommendations-heading"
        >
          {t("load")}
        </button>
        <button
          className="w-100 bg-transparent outline-none border-0 my-2 pg4-btn pg4-btn_link"
          disabled={!selectedId}
          onClick={handleDelete}
          aria-disabled={!selectedId} // Ensures screen readers understand the disabled state
          aria-describedby="recommendations-heading"
        >
          <i className="fa fa-trash me-2"></i> {t("delete")}
        </button>
      </div>
    </div>
  );
}

export default ExistingRecommendations;
