import { useQuery } from "react-query";
import { fetchSelfRegisteredPatients } from "../methods/api_methods";

export const useFetchPendingPatients = ({
  token,
  currentPracticeID,
  currentPractice,
  pageSize,
  sPageIndex,
  searchText,
}) => {
  return useQuery(
    ["patients", sPageIndex, pageSize, searchText],
    () =>
      fetchSelfRegisteredPatients({
        token,
        currentPracticeID,
        currentPractice,
        pageSize,
        sPageIndex,
        searchText,
      }),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );
};
