import React from "react";
import { useNavigate } from "react-router-dom";
// import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";

import { clearCookie } from "../../methods";
import { toggleSidebar } from "../../methods/sidenav";
import "../../styles/header.css";
import { ReactComponent as PgLogo } from "../../assets/PG_Logo.svg";
import { ReactComponent as PiLogo } from "../../assets/PureInsight_Logo_RGB_u1.svg";

import { clearGeneticState } from "../../store/actions/genetics";
import { useSelector, useDispatch } from "react-redux";
import NavButtons from "../NavButtons";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import ShippingCart from "./ShippingCart";
import { logoutAction } from "../../methods/auth_methods";

function Header() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const firstName = useSelector((state) => state.auth.firstName);
  const lastName = useSelector((state) => state.auth.lastName);
  const token = useSelector((state) => state.auth.token);
  const cookies = new Cookies();
  const language = useSelector((state) => state.auth.language);
  const currentPractice = useSelector((state) => state.auth.currentPractice);

  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPG2UserId = useSelector((state) => state.auth.currentPG2UserId);
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-white position-fixed w-100 fixed-top" >
      <nav className="container-fluid mx-0 navbar py-3 no-print">
        <div className="container-xl  px-0">
          {/* menu button */}
          <div className="icon-one d-md-none">
            <div
              className="hamburger hamburger-one"
              onClick={toggleSidebar}
            ></div>
          </div>

          {/* Logo */}
          <div
            className="m-auto m-md-0 cursor-pointer pg-logo"
            onClick={() => navigate("/")}
          >
            {/* <PgLogo fill="#fff" fontSize="100px" /> */}
            {i18n.language == "en_gb" ? (
              <PgLogo fill="#fff" fontSize="100px" />
            ) : (
              <PiLogo fill="#fff" fontSize="100px" />
            )}
          </div>
          {isAuth && (
            <div className="d-flex ">
              <span
                className="pg-text__bold text-decoration-none text-color__p mx-2 d-none d-lg-inline-block cursor-pointer"
                style={{ verticalAlign: "top", color: "" }}
              >
                {firstName + " " + lastName}
              </span>
              <Dropdown className="d-inline-block">
                <Dropdown.Toggle as="i" bsPrefix="text-danger">
                  <i
                    className="fas fa-user-circle cursor-pointer user-icon"
                    style={{ color: "#2470c2" }}
                  ></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate("/patient/account")}>
                    <span style={{ verticalAlign: "top" }}>
                      {t("patient_account_txt1")}
                    </span>
                  </Dropdown.Item>
                  <hr />
                  {language === "en_gb" && (
                    <>
                      <Dropdown.Item
                        onClick={() => navigate("/patient/manage_data")}
                      >
                        <span style={{ verticalAlign: "top" }}>
                          Manage Data
                        </span>
                      </Dropdown.Item>
                      <hr />
                    </>
                  )}
                  <Dropdown.Item onClick={logoutAction}>
                    <span style={{ verticalAlign: "top" }}>
                      {t("patient_logout")}
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {language === "en" && (
                <>
                  <ShippingCart />
                </>
              )}
            </div>
          )}
        </div>
      </nav>
      <NavButtons show="patient" />
    </div>
  );
}

export default Header;
