const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const createOrUpdateRecommendation = async ({
  currentPracticeID,
  currentPractice,
  token,
  data,
  method = "POST",
}) => {
  const url = `${protocol}//${currentPractice}${PG3_BASE_URL}/add_custom_report_recommendation`;

  const response = await fetch(url, {
    method: method,
    headers: {
      Authorization: token,
      "Current-Practice": currentPracticeID,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (response.status === 400) {
    const errorData = await response.json();
    throw {
      status: 400,
      message: errorData.message || "Bad Request",
      data: errorData,
    };
  }
  if (!response.ok) {
    throw new Error(response.json());
  }

  return response.json();
};

export const getRecommendations = async ({
  currentPracticeID,
  currentPractice,
  token,
  recommendation_category,
}) => {
  const url = `${protocol}//${currentPractice}${PG3_BASE_URL}/get_recommendations_templates?recommendation_category=${recommendation_category}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
      "Current-Practice": currentPracticeID,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status} ${response.statusText}`);
  }

  return response.json();
};

export const deleteRecommendation = async ({
  currentPracticeID,
  currentPractice,
  token,
  id,
}) => {
  const url = `${protocol}//${currentPractice}${PG3_BASE_URL}/delete_recommendations_template?id=${id}`;

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: token,
      "Current-Practice": currentPracticeID,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status} ${response.statusText}`);
  }

  return response.json();
};
