import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PatientApprovalTable from "../../components/Practice/SelfRegistration/PatientApprovalTable";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

export const SelfRegistrationApproval = ({ url }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedPatient, setSelectedPatient] = useState([]);

  return (
    <div className="container-fluid">
      <Helmet>
        <title>{t("puregenomics_1")}</title>
      </Helmet>
      <div className="container-xxl py-5 px-lg-0">
        <div
          className="cursor-pointer textc-primary pg-link"
          onClick={() => navigate("/practice/dashboard")}
        >
          <i className="fas fa-chevron-left mr-2"></i> {t("dashboard")}
        </div>
        <div className="my-4 pg-title">{t("self_reg_patients")}</div>
        <div>
          <PatientApprovalTable
            // patient={patients || []}
            // updatePatientTable={updatePatientTable}
            // totalData={patients?.total || 0}
            selectedPatient={selectedPatient}
            setSelectedPatient={setSelectedPatient}
            currentPractice={currentPractice}
            t={t}
          />
        </div>
      </div>
    </div>
  );
};

export default SelfRegistrationApproval;
