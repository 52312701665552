import { useState, useMemo, useEffect } from "react";
import { Modal, ModalDialog } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion";
import RecommendationForm from "./NewRecommendationForm";
import ExistingRecommendations from "./ExistingRecommendation";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { updatePG4ReportData } from "../../../../store/actions/pg4report";
import { recommendations_cat_key_map } from "../../../../constant";
import {
  createOrUpdateRecommendation,
  deleteRecommendation,
} from "./recommendation_api";
import { useTranslation } from "react-i18next";

const UI_STATES = {
  INITIAL: "initial",
  CREATE_NEW: "createNew",
  USE_EXISTING: "useExisting",
  GET_TITLE: "getTitle",
};

function AddRecommendation({ show, onCancel, report_id, ...props }) {
  const { t } = useTranslation();
  const [uiState, setUiState] = useState(UI_STATES.INITIAL);
  const [loadedRecommendation, setLoadedRecommendation] = useState(null);
  const [pendingRecommendationData, setPendingRecommendationData] =
    useState(null);
  const [action, setAction] = useState("");
  const [titleError, setTitleError] = useState(null);

  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const reportData = useSelector((state) => state.pg4Report.reportData);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const updatePG4ReportDataAction = (data) =>
    dispatch(updatePG4ReportData(data));

  const recommendationMutation = useMutation(
    async ({ data, method }) =>
      createOrUpdateRecommendation({
        currentPractice,
        currentPracticeID,
        token,
        data,
      }),
    {
      onSuccess: (data) => {
        const current_category = recommendations_cat_key_map[props.context];
        const newRecommendation = data.data || data;
        const newReportData = {
          ...reportData,
          [current_category]: {
            ...reportData[current_category],
            recommendations: [
              newRecommendation,
              ...reportData[current_category]?.recommendations,
            ],
          },
        };
        updatePG4ReportDataAction(newReportData);
        queryClient.invalidateQueries("recommendations");
      },
      onError: (error) => {
        console.log("errrrrrrrr->", { error });
        if (error?.status === 400) {
          setTitleError({ message: t("sr_txt_15"), timestamp: Date.now() });
        } else {
          setTitleError({
            message: t("pg4_patient_rec_all_err"),
            timestamp: Date.now(),
          });
        }
      },
    }
  );

  const deleteMutation = useMutation(
    async (id) =>
      deleteRecommendation({ currentPractice, currentPracticeID, token, id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("recommendations");
      },
    }
  );

  const handleCreateNew = () => {
    setUiState(UI_STATES.CREATE_NEW);
  };

  const handleUseExisting = () => {
    setUiState(UI_STATES.USE_EXISTING);
  };

  const handleLoadRecommendation = (recommendation) => {
    setLoadedRecommendation(recommendation);
    setUiState(UI_STATES.CREATE_NEW);
  };

  const handleSaveNew = (recommendationData) => {
    setPendingRecommendationData(recommendationData);
    setUiState(UI_STATES.GET_TITLE);
  };

  const handleTitleSubmit = async (title) => {
    try {
      const data = {
        ...pendingRecommendationData,
        title: title,
        is_save: true,
      };
      await recommendationMutation.mutateAsync({
        data: data,
        method: "POST",
      });
      setPendingRecommendationData(null);
      onCancel();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBack = () => {
    if (uiState === UI_STATES.GET_TITLE) {
      setUiState(UI_STATES.CREATE_NEW);
    } else {
      setUiState(UI_STATES.INITIAL);
    }
  };

  useEffect(() => {
    if (pendingRecommendationData) console.log({ pendingRecommendationData });
    if (loadedRecommendation) console.log({ loadedRecommendation });
  }, [pendingRecommendationData, loadedRecommendation]);

  const modalTitle = useMemo(() => {
    switch (uiState) {
      case UI_STATES.INITIAL:
        return t("Add Recommendation");
      case UI_STATES.CREATE_NEW:
        return t("Create New Recommendation");
      case UI_STATES.USE_EXISTING:
        return t("Use Existing Recommendation");
      case UI_STATES.GET_TITLE:
        return t("Enter Recommendation Title");
      default:
        return t("Add Recommendation");
    }
  }, [uiState, t]);

  const content = useMemo(() => {
    switch (uiState) {
      case UI_STATES.INITIAL:
        return (
          <motion.div
            key={UI_STATES.INITIAL}
            initial={{ x: "110%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "-120%", opacity: 0 }}
            transition={{ duration: 0.5, type: "spring", bounce: 0 }}
          >
            <CreateOrUseExisting
              t={t}
              onCreateNew={handleCreateNew}
              onUseExisting={handleUseExisting}
            />
          </motion.div>
        );
      case UI_STATES.CREATE_NEW:
        return (
          <motion.div
            key={UI_STATES.CREATE_NEW}
            initial={{ x: "110%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "-120%", opacity: 0 }}
            transition={{ duration: 0.5, type: "spring", bounce: 0 }}
          >
            <RecommendationForm
              {...props}
              report_id={report_id}
              loadedRecommendation={loadedRecommendation}
              onBack={handleBack}
              onSaveNew={handleSaveNew}
              onCancel={onCancel}
              setAction={setAction}
              action={action}
              recommendationMutation={recommendationMutation}
              t={t}
            />
          </motion.div>
        );
      case UI_STATES.USE_EXISTING:
        return (
          <motion.div
            key={UI_STATES.USE_EXISTING}
            initial={{ x: "110%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "-120%", opacity: 0 }}
            transition={{ duration: 0.5, type: "spring", bounce: 0 }}
          >
            <ExistingRecommendations
              t={t}
              onLoad={handleLoadRecommendation}
              onBack={handleBack}
              deleteMutation={deleteMutation}
              {...props}
            />
          </motion.div>
        );
      case UI_STATES.GET_TITLE:
        return (
          <motion.div
            key={UI_STATES.GET_TITLE}
            initial={{ x: "110%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "-120%", opacity: 0 }}
            transition={{ duration: 0.5, type: "spring", bounce: 0 }}
          >
            <GetRecommendationTitle
              onSubmit={handleTitleSubmit}
              onBack={() => setUiState(UI_STATES.CREATE_NEW)}
              t={t}
              titleError={titleError}
            />
          </motion.div>
        );
      default:
        return null;
    }
  }, [
    uiState,
    handleCreateNew,
    handleUseExisting,
    handleBack,
    handleLoadRecommendation,
    handleSaveNew,
    handleTitleSubmit,
    loadedRecommendation,
    onCancel,
    props,
    report_id,
    action,
    recommendationMutation,
    deleteMutation,
  ]);

  return (
    <Modal
      show={show}
      onHide={onCancel}
      size="lg"
      aria-label={modalTitle}
      aria-modal="true"
      backdrop="static"
      className="edit-practitioner-modal modal-dialog-scrollable overflow-x-hidden"
      contentClassName="overflow-x-hidden"
      centered
      id="add-recommendation-modal"
      dialogAs={motion.div}
      {...{ layout: true }}
    >
      <ModalDialog size="lg" className="">
        <div className="d-flex justify-content-end">
          <button
            className="pg4-btn bg-transparent fs-3"
            onClick={onCancel}
            aria-label={t("close")}
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 4 L20 20 M20 4 L4 20"
                stroke="black"
                strokeWidth={2}
              />
            </svg>
          </button>
        </div>
        <Modal.Body className="pt-0">
          <AnimatePresence mode="popLayout" initial={false}>
            {content}
          </AnimatePresence>
        </Modal.Body>
      </ModalDialog>
    </Modal>
  );
}

export default AddRecommendation;

function GetRecommendationTitle({ onSubmit, onBack, t, titleError }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm();

  useEffect(() => {
    if (titleError) {
      setError("title", { type: "manual", message: titleError?.message });
    }
  }, [titleError, setError]);

  const handleTitleSubmit = (data) => {
    onSubmit(data.title);
  };

  return (
    <form onSubmit={handleSubmit(handleTitleSubmit)}>
      {console.log({ errors })}
      <h2 className="pg4-text__heading text_primary">{t("sr_txt_14")}</h2>
      <div className="mb-3">
        <label htmlFor="title" className="pg4-text pg4-text__bold text_primary">
          {t("sr_txt_4")}
        </label>
        <input
          type="text"
          className="form-control my-2"
          id="title"
          placeholder={t("pract_account_th1")}
          {...register("title", { required: t("sr_txt_13") })}
        />
      </div>
      {errors.title && (
        <p className="text-danger my-2" role="alert">
          {errors.title.message}
        </p>
      )}
      <div className="text-center">
        <button
          type="submit"
          className="pg4-btn pg4-btn_primary"
          disabled={isSubmitting}
        >
          {t("sr_txt_5")}
        </button>
      </div>
    </form>
  );
}

function CreateOrUseExisting({ onCreateNew, onUseExisting, t }) {
  return (
    <div className="">
      <h2 className="pg4-text__heading ff-m text_primary">{t("sr_txt_1")}</h2>
      <div className="mt-4 d-flex gap-2">
        <button
          className="pg4-btn pg4-btn_primary px-5 flex-fill"
          onClick={onCreateNew}
        >
          {t("sr_txt_2")}
        </button>
        <button
          className="pg4-btn pg4-btn_outline px-5 flex-fill"
          onClick={onUseExisting}
        >
          {t("sr_txt_3")}
        </button>
      </div>
    </div>
  );
}
