import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { parseUrl } from "query-string";
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next'
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import { LOGOUT, sendOTPForMultiDomain } from "../../store/actions/auth";
import { PgRadioButton } from "../../components/PgRadioButton";
import { switchSDAndLogin } from "../../methods/auth_methods";

function Domain() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.token);
  const postLogin = useSelector((state) => state.auth.postLogin);
  const userID = useSelector((state) => state.auth.currentUserId);
  const practices = useSelector((state) => state.auth.practices);
  const termsAgreed = useSelector((state) => state.auth.termsAgreed);
  const language = useSelector((state) => state.auth.language)
  const logoutAction = () => dispatch({ type: LOGOUT });
  const sendOTPForMultiDomainAction = () =>
    dispatch(sendOTPForMultiDomain(userID, practiceID));

  const [practiceName, setPracticeName] = useState("");
  const [practiceID, setPracticeID] = useState(null);
  const [isMfaEnabled, setMfaEnabled] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const selectPractice = (practiceID, practiceName, mfa_enabled) => {
    setMfaEnabled(mfa_enabled);
    setPracticeID(practiceID);
    setPracticeName(practiceName);
  };

  useEffect(() => {
    if (practices.length <= 1) return navigate("/practice/dashboard");
  }, []);

  const selectDomain = (e) => {
    e.preventDefault();
    if (practiceName === "")
      return toast.error(t('txt_value_3'));
    setLoading(true);
    sendOTPForMultiDomainAction().then((response) => {
      setLoading(false);
      if (response.error) {
        console.log("error multi domain");
      } else {
        if (response.payload.mfa_enbaled) {
          navigate("/users/verify_otp", {
            state: {
              toReport: location?.state?.toReport,
              report: location.state.report,
              __from: "DomainPage",
              practiceName,
              practiceID,
            }
          });
        } else {
          let path = `/practice/dashboard`,
            subdomain = practiceName,
            _practiceID = practiceID;
          if (postLogin) path = `/hcp/educational_1`;
          if (location.state?.toReport) {
            const to = parseUrl(
              location?.state?.report?.from?.search ?? ""
            ).query;
            const subdomain = window.location.host.split(".")[0];
            const currentPractice = _.find(practices, { subdomain });
            if (to.patient_id && currentPractice) {
              path = `/practice/patient/report${location?.state?.report?.from?.search}`;
              subdomain = currentPractice.subdomain;
              _practiceID = currentPractice.id;
            }
          }
          switchSDAndLogin(token, subdomain, _practiceID, path, termsAgreed, language);
        }
      }
    });
  };

  return (
    <div className="container sign-in__container">
      {loading ? (
        <div className="text-center my-5 py-5">
          Loading...
        </div>
      ) : (
        <>
          <div className="py-2"></div>
          <div className="row justify-content-center my-4 mt-5">
            <div className="col-10 col-md-4 pg-text">
              <form onSubmit={selectDomain}>
                <div className="pg-heading">{t('txt_value_1')}</div>
                <div className="my-5 mx-5">
                  {practices?.length > 1 &&
                    practices?.map((practice) => {
                      return (
                        <PgRadioButton
                          key={practice.id}
                          title={practice.name}
                          value={practice.subdomain}
                          onCheck={() =>
                            selectPractice(
                              practice.id,
                              practice.subdomain,
                              practice.mfa_enabled
                            )
                          }
                          checked={practiceID === practice.id}
                          name={practices.subdomain}
                          id={`radio-btn__subdomain__${practice.subdomain}`}
                        />
                      );
                    })}
                </div>
                <input
                  type="submit"
                  className="float-end btn bgc-primary text-light px-5 py-2 my-1 mb-5"
                  value={t('txt_value_2')}
                  id="submit-btn__subdomain"
                />
              </form>
              <div className="py-5 my-5"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default Domain;
