import React, { useState, useEffect } from "react";
import { parse } from "query-string";
import { useSelector, useDispatch } from 'react-redux';
import * as _ from "lodash";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


import { Loading } from "../../components/Loading";
import { getReportDiagrams } from "../../store/actions/patientReport";
import { generatePDF } from "../../methods";
import { L_ReportDiagram } from "../../components/LoadingEffectComponent/Report/L_ReportDiagram";
import toastr from "react-hot-toast";

function ReportDiagrams({ history }) {

  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const diagrams = useSelector(state => state.patientReport.reportDiagrams);
  const patientInfo = useSelector(state => state.patientReport.patientDetail);
  const loading = useSelector(state => state.patientReport.isGettingReportDiagram);
  const currentReportId = useSelector(state => state.patientReport.currentReportId);
  const isUrl = useSelector(state => state.patientReport.isUrl);
  const currentPatientId = useSelector(state => state.patientReport.currentPatientId);
  const userType = useSelector(state => state.auth.userType);

  // redux actions
  const dispatch = useDispatch();

  const getReportDiagramsAction = (token, currentPracticeID, currentPractice, id) => dispatch(getReportDiagrams(token, currentPracticeID, currentPractice, id));
  const { t, i18n } = useTranslation();
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const navigate = useNavigate();
  const generatePDFAction = async () => {
    setIsGeneratingPDF(true);
    generatePDF("report-diagrams", setIsGeneratingPDF);
  };
  const protocol = window.location.protocol;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  const fetchAndPrintDiagramsPDF = async () => {
    let __wt = window.open("/loading");
    window.focus();
    fetch(
      `${protocol}//${currentPractice}${PG3_BASE_URL}/reports/${currentReportId}/${currentPatientId}/diagrams_pdf_view.pdf`,
      {
        headers: {
          "Content-Type": "application/pdf",
          Authorization: token,
          "Current-Type": userType,
          "Current-Practice": currentPracticeID,
        }
      }).then(response => {
        if (response.ok) {
          return response.blob()
        }
        else {
          __wt.close();
          toastr.error("Failed to load PDF");
        }

      }).then(text => {
        // creating a location for pdf file in memory
        var url = window.URL.createObjectURL(new Blob([text], { type: "application/pdf" }));
        if (__wt.document.location) {
          __wt.document.location.href = url;
        }
      }).catch(err => {
        if (__wt)
          __wt.close();
        toastr.error("Failed to load PDF");
        console.log(err, 'err while loading PDF');
      })
  };

  useEffect(() => {
    getReportDiagramsAction(
      token,
      currentPracticeID,
      currentPractice,
      currentReportId
    ).then((response) => {
      if (response.error) {
        toast.error(response.payload.message);
        // navigate("/practice/dashboard");
      }
    });
  }, []);

  return (
    <div className="container-xxl px-2 my-3 mb-5">
      {loading ? (
        <L_ReportDiagram />
      ) : (
        <div className="container-fluid">
          <div
            className="pg-link my-5"
            onClick={() => navigate(-1)}
          >
            <i className="fas fa-chevron-left"></i> {t('pracrs_title')}
          </div>
          <div className="" id="report-diagrams">
            <div className="row justify-content-between my-3 mt-5 pg-text">
              <div className="col-12 col-sm-6">
                <div className="pg-title my-2">{t("diagrams")}</div>
                <div className="pg-heading my-4">
                  {patientInfo?.patient?.full_name}
                </div>
                <div dangerouslySetInnerHTML={{ __html: t('report_diagram_line_1') }} />
              </div>
              <div
                className="text-sm-end col-sm-5 col-12 pt-4 pg-link"
                onClick={fetchAndPrintDiagramsPDF}
              >
                <i className="fas fa-download px-3"></i> {t('download_all')}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                {!_.isEmpty(diagrams) ? (
                  Object.entries(diagrams).map(function (data) {
                    return (
                      <div>
                        <hr className="my-5" />
                        {/* <div style={{ maxHeight: "100vh", height: window.innerHeight > 750 ? '700px' : "90vh" }}> */}

                        <div>
                          <div className="text-start">
                            <span className="lh-lg btn btn-primary text-light">
                              {data[0].replaceAll("_", " ")}
                            </span>
                          </div>
                          {isUrl
                            ? <img src={data[1]} className="img-fluid" />
                            : <img src={`data:image/png;base64,${data[1]}`} className="img-fluid" />
                          }
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center">
                    {t('report_diagram_no_data')}
                    <div className="my-5 py-5"></div>
                    <div className="my-5 py-5"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default ReportDiagrams;
