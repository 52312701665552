import React from "react";
import SampleImage from "../../assets/iris-large.svg";
import Picture1 from "../../assets/Picture1.png";
import Picture2 from "../../assets/Picture2.png";
import Picture3 from "../../assets/Picture3.png";
import Picture4 from "../../assets/Picture4.png";
import Picture5 from "../../assets/Picture5.png";
import Picture6 from "../../assets/Picture6.png";
import Picture7 from "../../assets/Picture7.png";
import Picture8 from "../../assets/Picture8.png";
import Picture9 from "../../assets/Picture9.png";
import Picture10 from "../../assets/Picture10.png";
import { useTranslation } from "react-i18next";

function MeetOutTeam() {
  const { t, i18n } = useTranslation();
  return (
    <div className="my-5">
      <div className="container-xxl px-0 px-md-3 pt-2 pt-lg-0">
        {/* section 2 */}
        <div className="pgs-title mt-5 my-3 px-3 px-lg-0">
          <span className="pgs-title">MEET OUR </span>
          <span className="pgs-title__v">TEAM</span>
        </div>
        <div className="row justify-content-center">
          <div className="col-11 col-lg-12">
            <div className="pgs-heading fw-bold textc-primary my-2">
              Scientific Advisory Board
            </div>
            <div className="pgs-text my-2">
              Our global team leads the charge in scientifically and clinically
              validated nutrigenomics. This dynamic consortium includes
              certified functional medicine doctors, scientists, and genomics
              experts across the U.S., Canada, Europe, and Australia. Together,
              they work to support {i18n.language === "en_gb" ? "PureGenomics®" : "PureInsight™"} by integrating
              the latest in research and clinical applications into reports and
              recommendations so that you don’t have to. Please take a moment to
              meet our trusted team.
            </div>
          </div>
        </div>
        <br />
        {/* section 3 */}

        <div className="my-3">
          {/* p1 */}
          <div className="row my-3 justify-content-center justify-content-lg-around">
            <div className="col-11 col-lg-5 my-4">
              <div>
                <img src={Picture1} alt="Kelly C" className="meet-img my-3" />
              </div>
              <div className="pgs-heading__sub ff-m textc-primary fw-bold">
                Kelly C. Heim, Ph.D.* (Co-Chair)
              </div>
              <div className="pgs-text">
                Co-creator of{" "}
                <span className="textc-primary fw-normal">PureInsight<sup>&trade;</sup></span>
                , he oversees the evidence-based development of
                the platform and its educational resources. As a pharmacologist
                specializing in natural products, Dr. Heim is a published author
                and illustrator of various studies, scholarly reviews, and book
                chapters.
              </div>
            </div>
            <div className="col-11 col-lg-5 my-4">
              <div>
                <img
                  src={Picture2}
                  alt="Barry Ritz"
                  className="meet-img my-3"
                />
              </div>
              <div className="pgs-heading__sub ff-m textc-primary fw-bold">
                Barry Ritz, Ph.D.** (Co-Chair)
              </div>
              <div className="pgs-text">
                Dr. Ritz is a member of the American Society for Nutritional
                Sciences and executive board of the Council for Responsible
                Nutrition’s Medical Affairs Subcommittee. A former professor at
                Drexel University, Dr. Ritz is an expert in nutritional
                immunology and has numerous publications in scientific journals.
              </div>
            </div>

            <div className="col-11 col-lg-5 my-4">
              <div>
                <img
                  src={Picture3}
                  alt="Nathan Morris"
                  className="meet-img my-3"
                />
              </div>
              <div className="pgs-heading__sub ff-m textc-primary fw-bold">
                Nathan Morris, M.D.+
              </div>
              <div className="pgs-text">
                Co-creator of{" "}
                <span className="textc-primary fw-normal">PureInsight<sup>&trade;</sup></span>
                , functional medicine provider with over a
                decade of experience and founder of Good Medicine, a
                multidisciplinary functional medicine practice in Monument, CO.
              </div>
            </div>
            <div className="col-11 col-lg-5 my-4">
              <div>
                <img
                  src={Picture4}
                  alt="Penny Kendall-Reed"
                  className="meet-img my-3"
                />
              </div>
              <div className="pgs-heading__sub ff-m textc-primary fw-bold">
                Penny Kendall-Reed, N.D.+
              </div>
              <div className="pgs-text">
                Naturopath and director of natural therapies at the Urban
                Wellness Clinic in Toronto. Dr. Kendall-Reed travels worldwide
                lecturing on genetics, neuroendocrine disorders, and
                metabolic-related diseases and is the creator of the integrated
                genetic platform GeneRx.ca.
              </div>
            </div>

            <div className="col-11 col-lg-5 my-4">
              <div>
                <img
                  src={Picture5}
                  alt="Denise Furness"
                  className="meet-img my-3"
                />
              </div>
              <div className="pgs-heading__sub ff-m textc-primary fw-bold">
                Denise Furness, Ph.D.+
              </div>
              <div className="pgs-text">
                Geneticist, registered nutritionist, and founder of Your Genes
                and Nutrition, applying her knowledge in private practice
                working alongside some of Australia’s most prominent integrative
                Medical Doctors. She is also a pioneer in the field of
                nutrigenomics and personalised medicine with over 15 years of
                experience in the area.
              </div>
            </div>
            <div className="col-11 col-lg-5 my-4">
            </div>
          </div>
          <div className="col-11  mx-auto">
            <div
              className="pgs-text fw-bold text-grey"
              style={{ color: "rgb(145 145 145)" }}
            >
              + is a retained advisor for Pure Encapsulations
            </div>
            <div
              className="pgs-text fw-bold text-grey"
              style={{ color: "rgb(145 145 145)" }}
            >
              * is an employee of Atrium innovations
            </div>
            <div
              className="pgs-text fw-bold text-grey"
              style={{ color: "rgb(145 145 145)" }}
            >
              ** is an employee of Nestle Health Science
            </div>
          </div>
        </div>
        <br />
      </div >
    </div >
  );
}

export default MeetOutTeam;
