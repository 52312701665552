import React, { useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useMutation } from "react-query";
import _ from "lodash";
import TextInput from "./TextInput";

// Mock service to fetch practitioner data
const fetchPractitionerData = async (text) => {
  const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
  const response = await fetch(
    `${baseUrl}/opt_for_practitioner?search=${text}`,
    {
      headers: {},
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Failed to fetch practitioners");
  }
  return data;
};

function OptForSearch({
  showModal,
  setpCode,
  setShowOptSearchModel,
  pCodeRef,
  setpCodeX,
}) {
  const [text, setText] = useState("");
  const { mutate, data, error, isLoading } = useMutation((inputValue) =>
    fetchPractitionerData(inputValue)
  );

  const debouncedInput = useCallback(
    _.debounce((inputValue) => {
      if (inputValue) {
        mutate(inputValue);
      }
    }, 500),
    [mutate]
  );

  const onInput = (e) => {
    const inputValue = e?.target?.value;
    setText(inputValue);
    debouncedInput(inputValue);
  };

  const clickHandler = (code) => {
    setpCode(code);
    setpCodeX(code.slice(0, 2) + "x".repeat(code.length - 2));
    setShowOptSearchModel(false);
    pCodeRef.current.disabled = true;
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter shadow"
      contentClassName="border-radius"
      backdrop="static"
      className="edit-patient-modal "
      align="center"
      centered
      style={{ padding: "25px" }}
    >
      <Modal.Header closeButton={false} style={{ borderBottom: "none" }}>
        <div className="pg-text justify-content-center">
          <div className="pgs-heading my-0 my-md-4 px-2 text_primary">
            Search for Your Practitioner
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center px-3">
          <div className="col-12 col-md-8 col-lg-6 position-relative p-0">
            <TextInput
              placeholder="Provider Search by name or address (city, state, zip)"
              inputHandler={onInput} // Automatically handle the input without a button
              style={{ fontSize: 12 }}
            />
            {text && (
              <div className="position-absolute text-center bg-white w-100 gs-list">
                {/* Loading State */}
                {text.length > 0 && isLoading && (
                  <div className="text-center p-2">Loading...</div>
                )}

                {/* Error State */}
                {text.length > 0 && error && (
                  <div className="text-danger text-center p-2">
                    No provider found with "{text}"
                  </div>
                )}

                {/* No Result */}
                {text.length > 0 && data?.users?.length === 0 && !isLoading && (
                  <div className="text-secondary text-center p-2">
                    No user with name "{text}"
                  </div>
                )}

                {/* Search Results */}
                {!isLoading && data?.users?.length > 0 && text.length > 0 && (
                  <ul className="list-style-none px-1 m-0">
                    {data.users.map((searchItem) => (
                      <li
                        key={searchItem.id}
                        className="p-1 px-2 list-unstyled gs-list__item"
                        onClick={() => clickHandler(searchItem.code)}
                      >
                        <div className="position-relative">
                          <div className="fw-bold text-start text-capitalize">
                            {searchItem.full_name}
                          </div>
                          <div
                            className="d-flex fw-bold text-secondary"
                            style={{ fontSize: "12px" }}
                          >
                            <div className="mx-1">
                              {searchItem?.code?.slice(0, 2) +
                                "x".repeat(searchItem.code?.length - 2)}
                            </div>{" "}
                            |<div className="me-1">{searchItem.city}</div> |
                            <div className="me-1">{searchItem.state}</div> |
                            <div className="mx-1">{searchItem.zipcode}</div>
                          </div>
                          <div
                            id="arrow_1"
                            className="gs-list__arrow arrow-wrapper"
                          >
                            <div className="arrow arrow--right">
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default OptForSearch;
