import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "../../../../styles/PG4/pgcheckbox.scss";
import { useSelector } from "react-redux";
import { t } from "i18next";

function RecommendationForm({
  report_id,
  focusAreas,
  context = "diet",
  loadedRecommendation,
  onBack,
  onSaveNew,
  onCancel,
  setAction,
  action,
  recommendationMutation,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm({
    defaultValues: {
      recommendation: loadedRecommendation?.recommendation_text || "",
      focus_areas: [],
    },
  });

  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );

  const onSubmit = async (data) => {
    const recommendationData = {
      report_id,
      recommendation_category: context,
      focus_areas: data.focus_areas,
      recommendation_text: data.recommendation,
    };

    if (action === "add" || action === "addRecommendation") {
      await recommendationMutation.mutateAsync({ data: recommendationData });
      onCancel();
    } else if (action === "updateAndAdd") {
      await recommendationMutation.mutateAsync({
        data: {
          ...recommendationData,
          recommendation_id: loadedRecommendation.id,
        },
      });
      onCancel();
    } else if (action === "saveNew" || action === "saveAndAdd") {
      onSaveNew(recommendationData);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-labelledby="form-title">
      <h2 id="form-title" className="pg4-text__heading text_primary">
        {t("sr_txt_9")}
      </h2>
      <hr className="my-2 mb-4" />

      <fieldset>
        <legend className="visually-hidden">{t("report_focus_area")}</legend>
        <div className="d-flex flex-wrap align-items-center">
          {focusAreas?.map((fa, idx) => (
            <div key={idx} className="me-3 d-flex align-items-center">
              <input
                type="checkbox"
                id={`focus-area-${idx}`}
                name="focus_areas"
                value={fa.key}
                className="me-1 pg-checkbox"
                {...register("focus_areas", {
                  required: t("pg4_report_txt_13"),
                })}
                aria-label={`${t("select")} ${fa.value} ${t(
                  "report_focus_area"
                )}`}
                aria-checked={
                  Array.isArray(getValues("focus_areas"))
                    ? getValues("focus_areas").includes(fa.key)
                    : false
                }
              />
              <label
                htmlFor={`focus-area-${idx}`}
                className={`text-capitalize pill pill-${fa.key.toLowerCase()}`}
                style={{ fontSize: 14, lineHeight: 1.2, fontWeight: "unset" }}
              >
                {fa.value}
              </label>
            </div>
          ))}
        </div>
      </fieldset>

      {errors.focus_areas && (
        <div className="text-danger my-2" role="alert" aria-live="assertive">
          {errors.focus_areas.message}
        </div>
      )}

      <label htmlFor="recommendation-text" className="visually-hidden">
        {t("pg4_report_txt_12")}
      </label>
      <textarea
        id="recommendation-text"
        className="form-control mt-3"
        rows={5}
        placeholder={t("add_rec_placeholder")}
        {...register("recommendation", {
          required: t("pg4_report_txt_12"),
        })}
        aria-required="true"
        aria-describedby={
          errors.recommendation ? "recommendation-error" : undefined
        }
      />
      {errors.recommendation && (
        <div
          id="recommendation-error"
          className="text-danger"
          role="alert"
          aria-live="assertive"
        >
          {errors.recommendation.message}
        </div>
      )}

      <div className="d-flex gap-2 text-center my-3">
        {loadedRecommendation ? (
          <>
            <button
              type="submit"
              className="pg4-btn pg4-btn_primary flex-fill"
              disabled={isSubmitting}
              onClick={() => setAction("add")}
              aria-disabled={isSubmitting}
            >
              {t("add")}
            </button>
            <button
              type="submit"
              className="pg4-btn pg4-btn_outline flex-fill"
              disabled={isSubmitting}
              onClick={() => setAction("updateAndAdd")}
              aria-disabled={isSubmitting}
            >
              {t("sr_txt_10")}
            </button>
            <button
              type="submit"
              className="pg4-btn pg4-btn_outline flex-fill"
              disabled={isSubmitting}
              onClick={() => setAction("saveNew")}
              aria-disabled={isSubmitting}
            >
              {t("sr_txt_11")}
            </button>
          </>
        ) : (
          <>
            <button
              type="submit"
              className="pg4-btn pg4-btn_primary flex-fill"
              disabled={isSubmitting}
              onClick={() => setAction("addRecommendation")}
              aria-disabled={isSubmitting}
            >
              {t("sr_txt_9")}
            </button>
            <button
              type="submit"
              className="pg4-btn pg4-btn_outline flex-fill"
              disabled={isSubmitting}
              onClick={() => setAction("saveAndAdd")}
              aria-disabled={isSubmitting}
            >
              {t("sr_txt_12")}
            </button>
          </>
        )}
      </div>
    </form>
  );
}

export default RecommendationForm;
