import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import TableFooter from "./TableFooter";
import TextInputWithIcon from "../../TextInputWithIcon";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import {
  approvePatients,
  rejectPatients,
  fetchSelfRegisteredPatients,
} from "../../../methods/api_methods";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useFetchPendingPatients } from "../../../hooks/useFetchPendingPatients";
import { usePendingPatientsTable } from "../../../hooks/usePendingPatientsTable";
import { PromptModal } from "./PromptModal";
import L_PatientList from "../../LoadingEffectComponent/L_PatientList";
import ErrorBoundary from "../../ErrorBoundary";
import BuggyComponent from "../../BuggyComponent";
import Error from "../../Error";

export const PatientApprovalTable = ({ updatePatientTable, t }) => {
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const token = useSelector((state) => state.auth.token);

  const [controlledPageCount, setControlledPageCount] = useState(1);
  const [sPageIndex, setSPageIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [globalRowSelection, setGlobalRowSelection] = useState({});
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const {
    data: patientData,
    isLoading,
    error,
    refetch,
  } = useFetchPendingPatients({
    token,
    currentPracticeID,
    currentPractice,
    pageSize,
    sPageIndex,
    searchText,
  });

  useEffect(() => {
    if (patientData) {
      setControlledPageCount(Math.ceil(patientData.total / pageSize));
    }
    setSPageIndex(pageIndex);
  }, [patientData, pageSize]);

  const data = useMemo(() => patientData?.patients || [], [patientData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    selectedFlatRows,
    selectedRowIds,
    pageIndex,
    toggleAllRowsSelected,
  } = usePendingPatientsTable({
    data,
    controlledPageCount,
    sPageIndex,
  });

  useEffect(() => {
    setSPageIndex(pageIndex);
  }, [pageIndex]);

  const mutationApprove = useMutation(approvePatients, {
    onSuccess: () => {
      toast.success(t("provider_search_txt6"));
      refetch();
      toggleAllRowsSelected(false);
    },
    onError: (error) => {
      console.error("Error approving patients:", error);
      toast.error(t("provider_search_txt3"));
    },
  });

  const mutationReject = useMutation(rejectPatients, {
    onSuccess: () => {
      toast.success(t("provider_search_txt4"));
      toggleAllRowsSelected(false);
      refetch();
    },
    onError: (error) => {
      console.error("Error rejecting patients:", error);
      toast.error(t("provider_search_txt5"));
    },
  });

  const handleApprove = async () => {
    try {
      const selectedPatients = selectedFlatRows.map((row) => row.original.id);
      mutationApprove.mutate({
        selectedPatients,
        currentPracticeID,
        currentPractice,
        token,
      });
      setShowApproveModal(false);
    } catch (error) {
      console.error("Error approving patients:", error);
    }
  };

  const handleReject = async () => {
    try {
      const selectedPatients = selectedFlatRows.map((row) => row.original.id);
      mutationReject.mutate({
        selectedPatients,
        currentPracticeID,
        currentPractice,
        token,
      });
      setShowRejectModal(false);
    } catch (error) {
      console.error("Error rejecting patients:", error);
    }
  };

  // if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data</div>;

  return (
    <main>
      {showApproveModal && (
        <PromptModal
          showModal={showApproveModal}
          onHide={() => setShowApproveModal(false)}
          onOk={handleApprove}
          headerText={t("txt_are_you_sure")}
          bodyText={t("provider_search_txt1")}
          t={t}
          cancelText={t("cancel")}
          acceptText={t("approve")}
        />
      )}
      {showRejectModal && (
        <PromptModal
          showModal={showRejectModal}
          onHide={() => setShowRejectModal(false)}
          onOk={handleReject}
          headerText={t("txt_are_you_sure")}
          bodyText={t("provider_search_txt2")}
          cancelText={t("cancel")}
          acceptText={t("reject")}
          t={t}
        />
      )}
      <div className="d-flex my-3 mt-5 justify-content-between w-100">
        <div className="w-50">
          <TextInputWithIcon
            label="Patient Name"
            placeholder={t("pracpl_line_2")}
            value={searchText}
            inputHandler={(e) => setSearchText(e.target.value)}
            Icon={
              <i className="fa fa-search text-secondary" aria-hidden="true"></i>
            }
          />
        </div>
        <div className="d-flex gap-2">
          <button
            className="pg4-btn px-4"
            onClick={() => setShowRejectModal(true)}
            disabled={Object.keys(selectedRowIds || {}).length === 0}
          >
            {t("reject")}
          </button>
          <button
            className="pg4-btn px-4  pg4-btn_primary"
            onClick={() => setShowApproveModal(true)}
            disabled={Object.keys(selectedRowIds || {}).length === 0}
          >
            {t("approve")}
          </button>
        </div>
      </div>
      <ErrorBoundary fallback={Error}>
        {isLoading ? (
          <L_PatientList />
        ) : (
          <table {...getTableProps()} className="pg-table self-reg_table my-2">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({
                          onClick: column.disableSortBy
                            ? undefined
                            : column.getSortByToggleProps().onClick,
                        })
                      )}
                      style={{ width: column.width }}
                    >
                      {column.render("Header")}
                      {!column.disableSortBy && (
                        <span className="fs-4">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " ↓"
                              : " ↑"
                            : ""}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {data.length === 0 && !error && (
              <tbody>
                <tr>
                  <td colSpan="4">
                    <div className="w-100 text-center">
                      {t("pracpl_nodata")}
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {data.length > 0 && !error && (
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={`${row.isSelected ? "selected " : ""}`}
                    >
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        )}
      </ErrorBoundary>
      <TableFooter
        pageSize={pageSize}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={sPageIndex}
        pageOptions={pageOptions}
      />
    </main>
  );
};

export default PatientApprovalTable;
