import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/Patient/Header'
import '../styles/PG4/pg4styles.scss';
import '../styles/PG4/newradio.scss';
import '../styles/PG4/pg4text.scss';
// /home/rashid/Project/pg-3.0-web/src/Pages/PG4/pg4stlyes.scss
export default function PatientLayout({ children }) {
    return (
        <>
            <Header />
            <div className="mt-5 pt-5">
                <Outlet />
            </div>
        </>
    )
}
