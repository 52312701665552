import React from "react";
import { useTranslation } from "react-i18next";
import { encode }from 'url-safe-base64'

export default function ShowGenetics({ patientId }) {
	
	const { t, i18n } = useTranslation();

    const encoded_string = (patientId) => {
       let current_time_stamp = new Date().toLocaleString()

       let unrapped_string = "patient_id=" + patientId + "&vendor=dna_life&time="+ current_time_stamp
       return encode(btoa(unrapped_string))
    }

    const dnalifeUrl = (patientId) =>{
        return "https://puregenomics.nordicvms.com/default.aspx?pgref=" + encoded_string(patientId)
    }

	return (
		<div
			className="pq-card p-4 px-5"
			style={{
				backgroundColor: "#f6f6f6 !important",
				background: "#f6f6f6 !important",
				height: "390 !important",
			}}
		>
			<div className="pd-pg-heading text-center">
        { t('patient_dashboard_genetics_h1') }
			</div>
			<div className="justify-content-between">
				<div className="my-4">
					<div className="pg-text my-2 py-2"
											dangerouslySetInnerHTML={{ __html: t('patient_dashboard_genetics_t1') }} />
					<div className="text-center px-5 btn-px-0 mt-5">
					<a
						className="btn pd-btn px-5 my-3"
                        target="_blank"
						href={dnalifeUrl(patientId)}
					>
						{ t('patient_dashboard_genetics_t2') }
					</a>
					</div>
				</div>
			</div>
		</div>
	);
}



