import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { connect, useSelector, useDispatch } from "react-redux";
// import toastr from 'react-hot-toast'
import toastr from "react-hot-toast";
import PgSelect from "../../../components/PgSelect";
import TextInput from "../../../components/TextInput";
import { validateEmail } from "../../../methods";
import { addPatient } from "../../../store/actions/adminPatient";
import { getPractitionersList } from "../../../store/actions/adminPractitioner";
import { getCountries } from "../../../store/actions/adminUser";
import _ from "lodash";
import { PgRadioButton } from "../../../components/PgRadioButton";
import PgCheckbox from "../../../components/PgCheckbox";
import PgSelectSearch from "../../../components/PgSelectSearch";
import ReactDatePicker from "react-datepicker";
import { months, years } from "../../../methods/constant";
import moment from "moment";

function AddPatient({
  showModal,
  onCancel,
  addPatientAction,
  context,
  updatePatientTable,
  selectedRegion
}) {
  const token = useSelector((state) => state.auth.token);
  const regions = useSelector(state => state.auth.region)
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);
  const [showPractitioner, setShowPractitioner] = useState(false);
  const [practitionersList, setPractitionersList] = useState([]);

  const [isValidForm, setIsValidForm] = useState(false);
  //form fields
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [dob, setDOB] = useState(null);
  const [gender, setGender] = useState("");
  const [skincolor, setSkinColor] = useState("");
  const [country, setCountry] = useState("");
  const [practitionerName, setPractitionerName] = useState("");
  const [practitionerId, setPractitionerId] = useState("");

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [dobError, setDobError] = useState("");
  const [genderError, setGenderError] = useState(false);
  const [skinColorError, setskinColorError] = useState("");
  const [countryError, setCountryError] = useState(false);
  // const [geneticError, setGeneticError] = useState(false);
  const [practitionerNameError, setPractitionerNameError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [genetics, setGenetics] = useState("");
  const [lab, setLab] = useState("");
  const [questionnaire, setQuestionnaire] = useState(false);
  const [chooseLab, setChooseLab] = useState(false);
  const [selectedPractitioner, setselectedPractitioner] = useState({});
  const [pactitionersNameList, setPactitionersNameList] = useState([]);

  const onDone = () => {
    setEmailError(false);
    setfNameError(false);
    setlNameError(false);
    setDobError("");
    setGenderError("");
    setskinColorError(false);
    setCountryError(false);

    setPractitionerNameError("");
    if (email.length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (gender.length === 0) setGenderError("Gender is required");
    if (skincolor.length === 0) setskinColorError("Skin color is required");
    if (country.length === 0) setCountryError("Country is required");
    if (!_.isDate(dob)) setDobError('Select Valid Date!');
    if (!selectedPractitioner?.id)
      setPractitionerNameError("Practitioner Name is not available");
    // if (genetics.length === 0) setGeneticError("Genetic is required");
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      selectedPractitioner?.id &&
      lName.length !== 0 &&
      // genetics.length !== 0 &&
      _.isDate(dob) &&
      gender.length !== 0 &&
      skincolor.length !== 0 &&
      country.length !== 0
    ) {
      setIsValidForm(true);
    } else
      document
        .querySelectorAll(".add-patient-modal")[0]
        .scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (isValidForm) {
      handleDone();
    }
  }, [isValidForm]);

  useEffect(() => {
    var selectedCountry;
    if(country === "" && context === "patient-list" && selectedRegion !== "US" && selectedRegion !== "CA"){
      selectedCountry = "United Kingdom"
    }
    else if(context === "dashboard" && (regions.length === 1 && regions[0] !== "Global")) {
      selectedCountry = (regions[0] === "US") ? "United States" : (regions[0] === "CA") ? "Canada" : "United Kingdom"
    }
    else {
      selectedCountry = country
    }
    dispatch(getPractitionersList(token, selectedCountry)).then((response) => {
      if (!response.error) {
        if (context === "dashboard" && country === "" && (regions.length !== 1 || regions[0] === "Global")) {
          setPactitionersNameList([])
        }
        else {
          setPractitionersList(response.payload.practitioners)
        }
      }
    });
  }, [country]);

  useEffect(() => {
    if (country !== "" || context !== "dashboard" || (regions.length === 1 && regions[0] !== "Global"))
      setShowPractitioner(true)
  }, [country]);

  useEffect(() => {
    dispatch(getCountries(token)).then(response => {
      if (!response.error) {
        var availableCountries = response.payload?.countries
        if (regions.includes("UK/EU") || (context === "patient-list" && selectedRegion !== "US" && selectedRegion !== "CA")) {
          setCountry("");
          _.remove(availableCountries, function (n) {
            return n === "United States";
          });
          _.remove(availableCountries, function (n) {
            return n === "Canada";
          });
        } else if ((regions.includes("CA")) || (context === "patient-list" && selectedRegion === "CA")){
          setCountry("");
          setCountry("Canada");
        } else if (context !== "patient-list") {
          setCountry("")
          if (regions.length === 1 && regions[0] === "US") {
            setCountry("United States")
          } else if (regions.length === 1 && regions[0] === "CA"){
            setCountry("Canada")
          }
        }
        else {
          setCountry("United States")
        }
        setCountryList(availableCountries);
      }
    });
  }, []);

  // const getPractitionerId = (name) => {
  //   const practitioner = _.find(practitionersList, ['full_name', name])
  //   setPractitionerId(practitioner.id)
  //   setPractitionerName(name)
  // }

  const handleDone = async () => {
    let data = {
      patient: {
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        email: email.toLowerCase(),
        dob: moment(dob).format(),
        gender: gender,
        skin_color: skincolor,
        country: country,
        upload_lab: false,
        upload_snp: genetics === "patient" ? true : false,
        invite_questionnaire: questionnaire,
        patient_type: "real",
        created_by: selectedPractitioner?.id,
      },
    };
    setLoading(true);
    const response = await dispatch(addPatient(token, data));
    setLoading(false);
    if (response.error) {
      //toast.error("Email already exists in system. Please choose another");
      setIsValidForm(false);
    } else {
      if (context === "patient-list") updatePatientTable();
      toastr.success("New Patient Created");
      onCancel();
    }
  };

  const onPractitionerSelect = (pract) => {
    // setPractitionerId(pract?.id ?? "");
    // setPractitionerName(pract?.full_name ?? "");
    setselectedPractitioner(pract);
  };

  return (
    <div>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onCancel}
        contentClassName="border-radius"
        backdrop="static"
        className="add-patient-modal"
      >        
        <Modal.Body>
          <div
            style={{
              position: "absolute",
              right: 25,
              top: 15,
              fontSize: 20,
              cursor: "pointer",
              color: "var(--pg_primary)",
            }}
            onClick={onCancel}
          >
            <div className="fas fa-times"></div>
          </div>
          <div className="container">
            <div className="text-center pg-title">Add Patient</div>
            <div className="row">
              <div className="col-12">
                <TextInput
                  label="First Name"
                  showLabel={true}
                  required={true}
                  id="f-name__addpatient"
                  placeholder="Enter Patient First Name"
                  value={fName}
                  error={fNameError}
                  type="text"
                  errorMessage="First Name is required"
                  inputHandler={(e) => {
                    setfName(e.target.value);
                  }}
                />

                <TextInput
                  label="Last Name"
                  showLabel={true}
                  required={true}
                  id="l-name__addpatient"
                  placeholder="Enter Patient Last Name"
                  value={lName}
                  error={lNameError}
                  errorMessage="Last Name is required"
                  inputHandler={(e) => {
                    setlName(e.target.value);
                  }}
                />

                <TextInput
                  label="Email"
                  showLabel={true}
                  required={true}
                  emailField={true}
                  id="email-input__addpatient"
                  placeholder="Enter Patient Email"
                  value={email}
                  error={emailError}
                  errorMessage="Enter valid Email"
                  inputHandler={(e) => {
                    if (
                      e.target.value.length < 0 &&
                      !validateEmail(e.target.value)
                    ) {
                      setEmailError(true);
                    } else {
                      setEmailError(false);
                      setEmail(e.target.value);
                    }
                  }}
                />

                <div className="my-2 dateVal">
                  <div className="d-inline-block pg-text__bold textc-primary">Date Of Birth</div>
                  <ReactDatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <select
                          value={date.getFullYear()}
                          onChange={({ target: { value } }) => changeYear(value)}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          value={months[date.getMonth()]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    onChange={(date) => {
                      setDOB(date);
                    }}
                    style={{ width: "100%" }}
                    value={dob}
                    dateFormat="MM/dd/yyyy"
                    onChangeRaw={() => { setDOB("") }}
                    selected={dob}
                    className={(_.isEmpty(dobError)) ? "dateFld" : "dateFld error"}
                    placeholderText="Enter DOB MM/DD/YYYY"
                    minDate={new Date("1900-01-01")}
                    maxDate={new Date()}
                    popperPlacement="bottom-start"
                    popperModifiers={{
                      flip: {
                        behavior: ["bottom"]
                      },
                      preventOverflow: {
                        enabled: false
                      },
                      hide: {
                        enabled: false
                      }
                    }}
                  />
                  <div className="text-danger mx-2 my-1 transition-all" style={!(_.isEmpty(dobError)) ? { opacity: 1 } : { opacity: 0, minHeight: '16px' }} >
                    {!(_.isEmpty(dobError)) && <div dangerouslySetInnerHTML={{ __html: `<i class="fas fa-info-circle me-2"></i>` + dobError }}></div>}
                  </div>
                </div>
                <PgSelect
                  label="Gender"
                  showLabel={true}
                  required={true}
                  optional={false}
                  id="gender__addpatient"
                  setSelectedItem={setGender}
                  selectedItem={gender}
                  placeholder="Enter Patient Gender"
                  options={["Male", "Female", "Intersex"]}
                  error={genderError}
                />
                <PgSelect
                  label="Patient Skin Color"
                  showLabel={true}
                  required={true}
                  optional={false}
                  id="skincolor__addpatient"
                  setSelectedItem={setSkinColor}
                  selectedItem={skincolor}
                  placeholder="Patient Skin Color (Required for Vitamin D Polygenic Scoring)"
                  options={["Dark", "Medium", "Light"]}
                  error={skincolor !== "" ? "" : skinColorError}
                />
                {
                  (regions.includes("US") || selectedRegion === "US" || regions.includes("CA") || selectedRegion === "CA") ?
                  <TextInput
                    label="Country"
                    showLabel={true}
                    id="l-country__addpractitioner"
                    placeholder="Enter Country"
                    value={(regions.includes("US") || selectedRegion === "US") ? "United States" : "Canada"}
                    isDisable={true}
                    error={countryError}
                    inputHandler={(e) => {
                      setCountry(e.target.value);
                    }}
                  />
                  :
                  <PgSelect
                    label="Country"
                    showLabel={true}
                    required={true}
                    optional={false}
                    id="country__addpatient"
                    setSelectedItem={setCountry}
                    selectedItem={country}
                    placeholder="Enter Patient Country"
                    options={countryList}
                    error={countryError}
                  />
                }

                {showPractitioner && <PgSelectSearch
                  label="Practitioner"
                  showLabel={true}
                  required={true}
                  optional={false}
                  errorMessage={practitionerNameError}
                  id="practitioner-name__addpatient"
                  onSelect={onPractitionerSelect}
                  selectedItem={selectedPractitioner}
                  placeholder="Enter Practitioner"
                  options={practitionersList}
                  objectKey="full_name"
                  objectKey2="email"
                  error={practitionerName !== "" ? "" : practitionerNameError}
                />}
                <button
                  className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                  onClick={onDone}
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddPatient;
