import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import toastr from "react-hot-toast";
import { parse } from "query-string";
import _ from "lodash";

import PgSelect from "../../PgSelect";
import TextInput from "../../TextInput";
import { validateEmail } from "../../../methods";
import { PgRadioButton } from "../../PgRadioButton";
import L_EditPractitioner from "../../LoadingEffectComponent/Admin/L_EditPractitioner";

import {
  getPractitioner,
  editPractitioner,
  getPractitionerTypes,
  getPracticeGDPRRequestLogForAdmin,
  gdprRequest
} from "../../../store/actions/adminPractitioner";
import { getCountries } from "../../../store/actions/adminUser";
import { useLocation } from "react-router-dom";

import { } from '../../../store/actions/practiceDashboard';
import RequestLogTable from "../../Patient/GDPR/RequestLogTable";
import DeletePatientModal from "../../../Pages/Practice/DeletePatientModal";
import { licenseNotMandatoryTypes, licenseNotNeededTypes, stateList, caStateList } from '../../../constant'
import { useTranslation } from "react-i18next";

function EditPractitionerModal({
  onCancel,
  showModal,
  practitioner,
  updatePractitionerTable,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const location = useLocation();
  const params = parse(location.search);
  const selectedRegion = params["region"]

  const practitionersTypeList = useSelector(
    (state) => state.adminPractitioner.practitioner_types
  );

  const [gettingPracInfo, setgettingPracInfo] = useState(true);
  const [countryList, setCountryList] = useState([]);

  //form fields
  const [practitionerId, setPractitionerId] = useState("");
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [healthcareProviderType, setHealthcareProviderType] = useState("");
  const [
    otherHealthcareProviderType,
    setOtherHealthcareProviderType,
  ] = useState("");
  const [license, setLicense] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("US");
  const [userState, setUserState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [enableECommerce, setEnableECommerce] = useState(false);
  const [status, setStatus] = useState('')
  const [practiceName, setPracticeName] = useState("")
  const [practiceId, setPracticeId] = useState("")
  const [rialtoConfigurationId, setRialtoConfigurationId] = useState("")
  const [pureAccountNumber, setPureAccountNumber] = useState("")
  const [acceptRialtoTerms, setAcceptRialtoTerms] = useState(false)
  const [accountType, setAccountType] = useState("");

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [
    healthcareProviderTypeError,
    setHealthcareProviderTypeError,
  ] = useState("");
  const [address1Error, setAddress1Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [disableLicense, setDisableLicense] = useState(false);
  const [requireLicense, setRequireLicense] = useState(false);
  const [licenseError, setLicenseError] = useState(false);
  const [accountTypeError, setAccountTypeError] = useState('');
  const [addressValidation, setAddressValidation] = useState(false)
  const [atriumIdError, setAtriumIdError] = useState(false)

  const [practititionerTypesList, setPractititionerTypesList] = useState([]);
  const [practitionerTypeId, setPractitionerTypeId] = useState("");
  const [accountTypeList, setAccountTypeList] = useState([]);

  // gdpr
  const getRequestLog = (pID) => dispatch(getPracticeGDPRRequestLogForAdmin(token, pID));
  const [requestLogList, setrequestLogList] = useState([]);
  const [requestLogError, setrequestLogError] = useState(false);

  const [showActionPrompt, setshowActionPrompt] = useState(false);
  const [actionPromptMessage, setactionPromptMessage] = useState("");
  const [currentActionType, setCurrentActionType] = useState(null);
  const [showDeletePatientModal, setShowDeletePatientModal] = useState(false);

  useEffect(() => {
    if (selectedRegion === "EU")
      getRequestLog(practitioner.id).then(response => {
        if (!response.error) setrequestLogList(response.payload)
        else setrequestLogError(true)
      })
  }, [])


  // gdpr

  useEffect(() => {
    // console.log(practitioner)
    if (!practitioner.id) onCancel();
    else {
      dispatch(getPractitionerTypes(token)).then((response) => {
        if (!response.error) {
          const practitionerTypes = response.payload.practitioner_types;
          const accountTypes = response.payload.practice_account_types;
          setAccountTypeList(accountTypes)
          if (selectedRegion !== "US" && selectedRegion !== "CA") {
            setPractititionerTypesList(
              _.filter(practitionerTypes, ['regions', ["UK/EU"]]).map((a) => a.practitioner_type)
            );
          } else if (selectedRegion === "CA"){
            setPractititionerTypesList(
              _.filter(practitionerTypes, ['regions', ["CA"]]).map((a) => a.practitioner_type)
            );
          } else {
            setPractititionerTypesList(
              _.filter(practitionerTypes, ['regions', ["US"]]).map((a) => a.practitioner_type)
            );
          }
        }
      });
      dispatch(getPractitioner(token, practitioner.id)).then((response) => {
        if (!response.error) {
          const data = response.payload;
          setgettingPracInfo(false);
          setPractitionerId(data.id);
          setEmail(data.email || "");
          setfName(data.first_name || "");
          setlName(data.last_name || "");
          setPhone(data.phone_number || "");
          updateProviderType(data.practitioner_type_name || "")
          updateAccountType(data.account_type || "")
          setOtherHealthcareProviderType(data.other_healthcare_provider || "")
          setLicense(data.license || "");
          setAddress1(data.address_1 || "");
          setAddress2(data.address_2 || "");
          setAddress3(data.address_3 || "");
          setCity(data.city || "");
          setCountry(data.country || "US");
          setUserState(data.state || "");
          setZipcode(data.zipcode || "");
          setFax(data.fax || "");
          setEnableECommerce(data.enable_ecommerce || false);
          setStatus(data.aasm_state || "");
          setPracticeName(data.practice_name || "");
          setPureAccountNumber(data.pure_account_number || "");
          setAcceptRialtoTerms(data.terms_agreed || false)
          setAddressValidation(data.enable_ecommerce || false)
          setPracticeId(data.practice_id)
          setRialtoConfigurationId(data.rialto_configuration_id)
        } else {
          // toastr.error('Something went wr')
          onCancel();
        }
      });
      dispatch(getCountries(token)).then(response => {
        if (!response.error) {
          var availableCountries = response.payload?.countries
          if (selectedRegion !== "US" && selectedRegion !== "CA") {
            _.remove(availableCountries, function (n) {
              return n === "United States";
            });
            _.remove(availableCountries, function (n) {
              return n === "Canada";
            });
          } else if(selectedRegion === "CA"){
            _.remove(availableCountries, function (n) {
              return n === "United States";
            });
            setCountry("Canada");
          } else{
            setCountry("United States");
          }
          setCountryList(availableCountries);
        }
      });
    }
  }, []);

  const onDone = () => {
    setfNameError(false);
    setlNameError(false);
    setEmailError(false);
    setHealthcareProviderTypeError(false);
    setAddress1Error(false);
    setCityError(false);
    setStateError(false);
    setZipcodeError(false);
    setPhoneError(false);
    setLicenseError(false);
    setAccountTypeError(false);
    setAddressValidation(false)
    // if (providerTypes === "Other")

    if (email.length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (healthcareProviderType.length === 0)
      setHealthcareProviderTypeError(true);
    if (enableECommerce) setAddressValidation(true)
    if (address1.length === 0 && addressValidation) setAddress1Error(true);
    if (city.length === 0 && addressValidation) setCityError(true);
    if (userState.length === 0 && (selectedRegion === "US" || selectedRegion === "CA") && addressValidation) setStateError(true);
    if (zipcode.length === 0 && addressValidation) setZipcodeError(true);
    if (phone.length === 0 && addressValidation) setPhoneError(true);
    if (accountType.length === 0) setAccountTypeError('Account Type is Required ');
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      healthcareProviderType.length !== 0 &&
      (address1.length !== 0 || !addressValidation) &&
      (city.length !== 0 || !addressValidation) &&
      (userState.length !== 0 || !(selectedRegion === "US" || selectedRegion === "CA") || !addressValidation) &&
      (zipcode.length !== 0 || !addressValidation) &&
      (phone.length !== 0 || !addressValidation) && accountType.length !== 0
    ) {
      handleDone();
    } else {
      document
        .querySelectorAll(".edit-practitioner-modal")[0]
        .scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const handleDone = async () => {
    let data = {
      user: {
        // id: practitionerId,
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        email: email.toLowerCase(),
        other_healthcare_provider: otherHealthcareProviderType,
        license: license,
        address_1: address1,
        address_2: address2,
        address_3: address3,
        city: city,
        country: country,
        state: userState,
        zipcode: zipcode,
        phone_number: phone,
        fax: fax,
        practitioner_type_id: practitionerTypeId
      },
      practice_attributes: [{
        id: practiceId,
        name: practiceName,
        account_type: accountType,
        rialto_configuration_attributes: {
          id: rialtoConfigurationId,
          enable_e_commerce: enableECommerce,
          pure_account_number: pureAccountNumber
        }
      }]
    };
    dispatch(editPractitioner(token, practitioner.id, data)).then(
      (response) => {
        if (response.payload.invalid_customer && pureAccountNumber.length !== 0) {
          setAtriumIdError(true);
          document
            .querySelectorAll(".edit-practitioner-modal")[0]
            .scrollTo({ top: 0, behavior: "smooth" });
        }
        else if (!response.error) {
          toastr.success("Practitioner Updated successfully");
          onCancel();
          updatePractitionerTable();
        }
      }
    );
  };

  const getProviderTypeName = (providerId) => {
    const practitionerName = _.find(practitionersTypeList, ["id", providerId]);
    setOtherHealthcareProviderType(practitionerName?.practitioner_type);
    setPractitionerTypeId(providerId);
  };

  const getPractitionerTypeId = (type) => {
    const practitionerType = _.find(practitionersTypeList, [
      "practitioner_type",
      type,
    ]);
    setPractitionerTypeId(practitionerType?.id);
  };

  const updateProviderType = (option) => {
    getPractitionerTypeId(option);
    setHealthcareProviderType(option);
    if (licenseNotNeededTypes.includes(option)) {
      setDisableLicense(true);
      setLicense("");
    } else if (licenseNotMandatoryTypes.includes(option)) {
      setDisableLicense(false);
    } else {
      setDisableLicense(false);
    }
  };

  const gdprRequestFn = (message, actionType) => {
    setactionPromptMessage(message);
    setCurrentActionType(actionType);
    setshowActionPrompt(true)
  }

  const gdprRequestAPIFn = () => {
    dispatch(gdprRequest(token, practitioner.id, currentActionType)).then(response => {
      setshowActionPrompt(false);
      if (!response.error)
        toastr.success('Request sent successfully.')
    })
  }
  const updateAccountType = (option) => {
    setAccountType(option);
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      contentClassName="border-radius"
      backdrop="static"
      className="edit-practitioner-modal"
      id="edit-practitioner-modal"
    >
      <Modal.Body>
        <div
          style={{
            position: "absolute",
            right: 25,
            top: 15,
            fontSize: 20,
            cursor: "pointer",
            color: "var(--pg_primary)",
          }}
          onClick={onCancel}
        >
          <div className="fas fa-times"></div>
        </div>
        <div className="container">
          <div className="text-center pg-title">Manage A Practitioner</div>
          {gettingPracInfo ? (
            <L_EditPractitioner />
          ) : (
            <div className="row">
              {showDeletePatientModal && <DeletePatientModal
                showModal={showDeletePatientModal}
                onReject={() => setShowDeletePatientModal(false)}
              />}
              {showActionPrompt && <ActionPrompt
                show={showActionPrompt}
                onReject={() => setshowActionPrompt(false)}
                message={actionPromptMessage}
                onAccept={gdprRequestAPIFn}
              />}
              <div className="col-12">
                <TextInput
                  label="First Name"
                  showLabel={true}
                  required={true}
                  id="f-name__addpractitioner"
                  placeholder="Enter Practitioner First Name"
                  value={fName}
                  error={fName?.trim() === ""}
                  type="text"
                  errorMessage="First Name is required"
                  inputHandler={(e) => {
                    setfName(e.target.value);
                  }}
                />

                <TextInput
                  label="Last Name"
                  showLabel={true}
                  required={true}
                  id="l-name__addpractitioner"
                  placeholder="Enter Practitioner Last Name"
                  value={lName}
                  error={lName?.trim() === ""}
                  errorMessage="Last Name is required"
                  inputHandler={(e) => {
                    setlName(e.target.value);
                  }}
                />

                <TextInput
                  label="Email"
                  showLabel={true}
                  required={true}
                  emailField={true}
                  id="email-input__addpractitioner"
                  placeholder="Enter Practitioner Email"
                  value={email}
                  error={email === "" || !validateEmail(email)}
                  errorMessage="Enter valid Email"
                  inputHandler={(e) => {
                    // if (
                    //     e.target.value.length < 0 &&
                    //     !validateEmail(e.target.value)
                    // ) {
                    //     setEmailError(true)
                    // } else {
                    //     setEmailError(false)
                    setEmail(e.target.value);
                    // }
                  }}
                />
                <PgSelect
                  label="Account Type"
                  showLabel={true}
                  required={true}
                  id="account_type__addpractitioner"
                  setSelectedItem={updateAccountType}
                  selectedItem={accountType}
                  placeholder="Enter Account Type"
                  options={accountTypeList}
                  errorMessage="Account Type is required"
                  error={
                    accountType != ""
                      ? ""
                      : accountTypeError
                  }
                />
                <TextInput
                  label="Atrium Account ID"
                  showLabel={true}
                  optional={true}
                  id="puraccountnumber__addpractitioner"
                  placeholder="Enter Atrium Account ID"
                  value={pureAccountNumber}
                  error={atriumIdError}
                  errorMessage="Invalid Atrium ID"
                  isDisable={true}
                  inputHandler={(e) => {
                    setPureAccountNumber(e.target.value);
                  }}
                />
                {acceptRialtoTerms && !atriumIdError &&
                  <p className="pg-text__small textc-primary" style={{ marginTop: "-35px", fontStyle: "italic", fontWeight: "bold" }}> Virtual Dispensary Agreement has been accepted</p>
                }
                <PgSelect
                  label="Healthcare Provider Type"
                  showLabel={true}
                  required={true}
                  id="providerType__addpractitioner"
                  setSelectedItem={updateProviderType}
                  selectedItem={healthcareProviderType}
                  placeholder="Enter Healthcare Provider Type"
                  options={practititionerTypesList}
                  error={
                    healthcareProviderType !== ""
                      ? ""
                      : healthcareProviderTypeError
                  }
                />
                {
                  healthcareProviderType === "Other" &&
                  <TextInput
                    label="Other Healthcare Provider Type"
                    showLabel={true}
                    id="other-type__addpractitioner"
                    placeholder="Enter Other Healthcare Provider Type"
                    value={otherHealthcareProviderType}
                    inputHandler={(e) => {
                      setOtherHealthcareProviderType(e.target.value);
                    }}
                  />
                }
                <TextInput
                  label="License"
                  showLabel={true}
                  isDisable={disableLicense}
                  id="l-license__addpractitioner"
                  placeholder="Enter Practitioner License"
                  value={license}
                  inputHandler={(e) => setLicense(e.target.value)}
                />

                <TextInput
                  label="Practice Name"
                  showLabel={true}
                  optional={true}
                  id="practice-name__addpractitioner"
                  placeholder="Enter Practice Name"
                  value={practiceName}
                  inputHandler={(e) => {
                    setPracticeName(e.target.value);
                  }}
                />

                <TextInput
                  label="Street Address 1"
                  showLabel={true}
                  required={addressValidation}
                  id="l-address1__editpractitioner"
                  placeholder="Enter Address Line 1"
                  error={address1Error}
                  errorMessage="Address 1 is required"
                  value={address1}
                  inputHandler={(e) => setAddress1(e.target.value)}
                />
                <TextInput
                  label="Street Address 2"
                  showLabel={true}
                  id="l-address2__editpractitioner"
                  placeholder="Enter Address Line 2"
                  value={address2}
                  inputHandler={(e) => {
                    setAddress2(e.target.value);
                  }}
                />
                <TextInput
                  label="Street Address 3"
                  showLabel={true}
                  id="l-address3__editpractitioner"
                  placeholder="Enter Address Line 3"
                  value={address3}
                  inputHandler={(e) => {
                    setAddress3(e.target.value);
                  }}
                />
                <TextInput
                  label="City"
                  showLabel={true}
                  required={addressValidation}
                  id="l-city__editpractitioner"
                  placeholder="Enter City"
                  value={city}
                  error={cityError}
                  errorMessage="City is required"
                  inputHandler={(e) => {
                    setCity(e.target.value);
                  }}
                />
                {
                  (selectedRegion === "US" || selectedRegion === "CA") ?
                    <TextInput
                      label="Country"
                      showLabel={true}
                      id="l-country__addpractitioner"
                      placeholder="Enter Country"
                      value={selectedRegion === "US" ? "United States" : "Canada"}
                      isDisable={true}
                      error={countryError}
                      inputHandler={(e) => {
                        setCountry(e.target.value);
                      }}
                    />
                    :
                    <PgSelect
                      label="Country"
                      showLabel={true}
                      required={true}
                      optional={false}
                      id="country__addpatient"
                      setSelectedItem={setCountry}
                      selectedItem={country}
                      placeholder="Enter Patient Country"
                      options={countryList}
                      error={countryError}
                    />
                }
                {
                  (selectedRegion === "US" || selectedRegion === "CA") ?
                    <PgSelect
                      label={selectedRegion === "CA" ? "Province/Territory" : "State"}
                      showLabel={true}
                      required={addressValidation}
                      id="state__addpractitioner"
                      setSelectedItem={setUserState}
                      selectedItem={userState}
                      placeholder={selectedRegion === "CA" ? "Select Province/Territory" : "Enter State"}
                      options={selectedRegion === "CA" ? caStateList : stateList}
                      error={userState !== "" ? "" : stateError}
                    />
                    :
                    <TextInput
                      label="County"
                      showLabel={true}
                      id="state__addpractitioner"
                      placeholder="Enter County"
                      value={userState}
                      inputHandler={(e) => {
                        setUserState(e.target.value);
                      }}
                    />
                }
                <TextInput
                  label={selectedRegion === "CA" ? "Postal Code" : "Zipcode"}
                  showLabel={true}
                  required={addressValidation}
                  id="zipcode__addpractitioner"
                  placeholder={selectedRegion === "CA" ? "Enter Postal Code" : "Enter Zipcode"}
                  value={zipcode}
                  error={zipcodeError}
                  errorMessage={selectedRegion === "CA" ? "Postal Code is required" : "Zipcode is required"}
                  inputHandler={(e) => {
                    setZipcode(e.target.value);
                  }}
                />
                <TextInput
                  label="Phone Number"
                  showLabel={true}
                  required={addressValidation}
                  id="phone__addpractitioner"
                  placeholder="Enter Phone Number"
                  error={phoneError}
                  errorMessage="Phone Number is Required"
                  value={phone}
                  inputHandler={(e) => {
                    setPhone(e.target.value);
                  }}
                />
                <TextInput
                  label="Fax"
                  showLabel={true}
                  id="fax__addpractitioner"
                  placeholder="Enter Fax"
                  value={fax}
                  inputHandler={(e) => {
                    setFax(e.target.value);
                  }}
                />
                {/* <div className="d-inline-block pg-text__bold textc-primary">
                  Enable/Disabe E-Commerce
                </div>
                <div className="mx-4">
                  <PgRadioButton
                    name={"Enable"}
                    title={"Enable"}
                    checked={enableECommerce}
                    onCheck={() => {
                      setEnableECommerce(true);
                      setAddressValidation(true)
                    }}
                  />
                  <PgRadioButton
                    name={"Disable"}
                    title={"Disable"}
                    checked={!enableECommerce}
                    onCheck={() => {
                      setEnableECommerce(false);
                      setAddressValidation(false)
                    }}
                  />
                </div> */}

                <button
                  className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                  onClick={onDone}
                >
                  Update
                </button>
              </div>

              {selectedRegion === "EU" && <div>
                <div>
                  <hr className="my-4" />
                  <div className="col-12">
                    <div className="pg-heading text-center my-3">
                      Manage  Data
                    </div>
                    <div className="pg-text fw-bold">
                      This page facilitates GDPR data requests for your patients and your practice. Please click on one of the options below to make a request. You may also view the status of previous requests.
                    </div>
                    <div>
                      <div className="row my-3 mt-5 mt-md-3 justify-content-center">
                        <div className="col-12 col-md-6 my-auto">
                          <button
                            onClick={() => gdprRequestFn(
                              'Do you wish to proceed?',
                              'inactivate_account')}
                            className='btn btn-primary w-100'
                          >Inactivate My Account</button>
                        </div>
                      </div>
                      <div className="row my-3 mt-5 mt-md-3 justify-content-center">
                        <div className="col-12 col-md-6 my-auto">
                          <button
                            onClick={() => gdprRequestFn(
                              'Do you wish to proceed?',
                              'delete_data'
                            )}
                            className='btn btn-primary w-100'>Delete My Puregenomics Account</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <hr className="my-3" />
                <div className="pg-heading text-center my-2">Request Log</div>
                <RequestLogTable
                  logData={requestLogList}
                  error={requestLogError}
                />
              </div>}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EditPractitionerModal;

export const ActionPrompt = ({ show, onAccept, onReject, message, acceptLabel = 'Confirm', rejectLabel = 'Go Back' }) => {
  return (
    <Modal
      style={{
        background: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(.1)",
      }}
      centered
      show={show}
      onHide={onReject}
      bsPrefix="border-radius modal modal-2"
    >
      <div className="p-3">
        <div className="pg-text__bold">{message}</div>
        <div className="d-flex justify-content-end mt-4">
          <button className="btn bg-sky-blue px-4 mx-1" onClick={onReject}>{rejectLabel}</button>
          <button className="btn bgc-primary text-light px-5" onClick={onAccept}>{acceptLabel}</button>
        </div>
      </div>
    </Modal>)
}