import { Modal } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

export const PromptModal = ({
  showModal,
  onHide,
  onOk,
  t,
  headerText,
  bodyText,
  cancelText,
  acceptText,
}) => {
  return (
    <Modal show={showModal} onHide={onHide} centered>
      <ModalHeader>
        <Modal.Title className="fw-bold text_primary">{headerText}</Modal.Title>
      </ModalHeader>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <button className="pg4-btn px-3" onClick={onHide}>
          {cancelText}
        </button>
        <button className="pg4-btn pg4-btn_primary px-5" onClick={onOk}>
          {acceptText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
